
import React, { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useUserState } from "../../../../app-context/auth-context/AuthState";
import { EnhancedTableHead } from "../EnhancedTableHeader";
import { http } from "../../../../helper/http";
import LongMenu from "../../../../utils/longMenu/LongMenu";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const DeviceTable1 = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [combinedDevices, setCombinedDevices] = useState<any[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const { users }: any = useUserState();

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        console.log("Rows per page: ", rowsPerPage);
        console.log("Page: ", page);
        const fetchAllData = async () => {
          setLoading(true);
          try {
            const [assignedResp, overviewResp] = await Promise.all([
              http(`${baseUrl}/assign-device?userId=${users.user.id}`) as Promise<any>,
              http(`${baseUrl}/overview-dashboard`) as Promise<any>
            ]);
    
            const assignedDevices = assignedResp.devices.devices.map((item:any) => item.Device);
            const overviewDevices = overviewResp.devices;
    
            const allDevices = [...assignedDevices, ...overviewDevices];
            const uniqueDevices = Array.from(new Set(allDevices.map(d => d.id)))
              .map(id => allDevices.find(d => d.id === id))
              .filter((d): d is any => d !== undefined);
            console.log('uniqueDevices', uniqueDevices)
    
            setCombinedDevices(uniqueDevices);
            setTotalItems(uniqueDevices.length);
    
            // Calculate combined active and inactive counts
            const activeDevices = uniqueDevices.filter(d => d.status).length;
            const inactiveDevices = uniqueDevices.filter(d => !d.status).length;
    
            setActiveCount(activeDevices);
            setInactiveCount(inactiveDevices);
             setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
             setLoading(false);
          }
        };
    
        fetchAllData();
      }, [users.user.id]);
    
    const navigate = useNavigate()

    const handleMenuOptionClick = async (option: string, id: number) => {
        try {
            let _device: any;
            switch (option) {
                case "Data":
                    navigate("/data", { state: { deviceId: id } });
                    break;
                case "ModbusConfig":
                    navigate("/modbusConfig", { state: { deviceId: id } });
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const visibleRows = useMemo(
        () => combinedDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, combinedDevices]
      );
    
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Paper sx={{ width: '99%' }}>
                <p className="font-semibold text-xl text-slate-600 mb-4 py-1 px-2">Devices</p>
                <div className="h-[65vh] overflow-y-auto">
                    <TableContainer>
                        <Table className="min-w-750 bg-white" aria-labelledby="tableTitle" size="medium">
                            <EnhancedTableHead />
                            <TableBody className="">
                                {visibleRows.map((row: any) => (
                                    <TableRow key={row.id} hover className="cursor-pointer transition-all hover:bg-gray-100">
                                        <TableCell className="text-center" style={{ textAlign: 'center', padding: '10px' }}>
                                            {row.deviceId}
                                        </TableCell>
                                        <TableCell className="text-center" style={{ textAlign: 'center' }}>
                                            {row.deviceName}
                                        </TableCell>
                                        <TableCell className="text-center" style={{ textAlign: 'center' }}>
                                            {row.latitude}, {row.longitude}
                                        </TableCell>
                                        <TableCell className="text-center" style={{ textAlign: 'center' }}>
                                            <span
                                                className={`text-${row.status ? 'green-600' : 'red-600'} 
                      ${row.status ? 'bg-green-200' : 'bg-red-100'} 
                      rounded-md 
                      ${row.status ? 'px-4 py-2' : 'px-3 py-2'} `}
                                            >
                                                {row.status ? 'Active' : 'Inactive'}
                                            </span>
                                        </TableCell>
                                        {/* <ProtectedComponent roleids={[2]}> */}
                                        <TableCell
                                            className="text-center"
                                            style={{ textAlign: "center" }}
                                        >
                                            <LongMenu
                                                // options={
                                                //   users.user.id == 17
                                                //     ? ["Data", "Publish"]
                                                //     : ["Data" ,"ModbusConfig"]
                                                // }


                                                options={
                                                    ["Data", row.modbusConfig === false ? '' : "ModbusConfig"]
                                                }
                                                onOptionClick={handleMenuOptionClick}
                                                id={row.id}
                                            />
                                        </TableCell>
                                        {/* </ProtectedComponent> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 50, 100]}
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="bg-gray-100"
                />
            </Paper>
        </div>
    )
}

export default DeviceTable1