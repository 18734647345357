// import React, { useState, useEffect } from 'react';
// import { http } from '../../../helper/http';
// import {
//     Button,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     FormControl,
//     IconButton,
//     InputLabel,
//     MenuItem,
//     Select,
//     TextField,
//   } from "@mui/material";
//   import { useUserState } from '../../../app-context/auth-context/AuthState';

// interface SensorConfig {
//   tagName: string;
//   registerType: string;
//   registerAddress: string;
//   slaveId: string;
//   scalingFactor: string;
//   dataType: string;
//   bitValues: string;
// }

// const ModbusConfig = () => {
//   const [sensorConfigs, setSensorConfigs] = useState<SensorConfig[]>([]);
//   const [tagName, setTagName] = useState('vlt_phase_r');
//   const [registerType, setRegisterType] = useState('Holding Register');
//   const [registerAddress, setRegisterAddress] = useState('40001');
//   const [slaveId, setSlaveId] = useState('03');
//   const [scalingFactor, setScalingFactor] = useState('0.01');
//   const [dataType, setDataType] = useState('Float');
//   const [bitValues, setBitValues] = useState('16');
//   const [isPopupOpen, setIsPopupOpen] = useState(false);

//   const handleClosePopup = () => {
//     setIsPopupOpen(false);
//   };

//   const handleButtonClick = () => {
//     setIsPopupOpen(true);
//   };

//   const handleAddClick = () => {
//     const newConfig: SensorConfig = {
//       tagName,
//       registerType,
//       registerAddress,
//       slaveId,
//       scalingFactor,
//       dataType,
//       bitValues
//     };
//     setSensorConfigs([...sensorConfigs, newConfig]);
//     console.log(sensorConfigs);
//   }

//   const [devices, setDevices] = useState<any>([]);
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   const fetchData = async () => {
//     try {
//       const response = (await http(`${baseUrl}/device?alldevice=1`)) as any;
//       setDevices(response.devices);
//       console.log('response', response);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const [tags, setTags] = useState<any>([]);
//   const fetchTagBasedONDeviceID = async (deviceId: number) => {
//     try {
//       const response = (await http(`${baseUrl}/tags?deviceId=${deviceId}`)) as any;
//       setTags(response.tags);
//     } catch (error) {
//       console.error("Error fetching device data:", error);
//     }
//   };

//   const { users } = useUserState();
//   const [formData, setFormData] = useState({
//     customerId: users.user.customerId,
//     deviceId: "",
//     tagId: "",
//     status: false,
//     operand: "",
//     alertName: "",
//   });

//   const handleChange = (event: any) => {
//     const { name, value, type, checked } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//     if (name === "deviceId") {
//       fetchTagBasedONDeviceID(value);
//     }
//   };

//   return (
//     <>
//       <div className='w-[1082px]'>
//         <div className='flex py-3 shadow-sm gap-4 px-2'>
//           <div className='flex ml-4 mt-3 gap-4 w-[90%]'>
//             <img src="/images/ModbusConfigurations.png" className='w-[40px] h-[30px]' alt="" />
//             <p className='text-lg font-medium font-inter leading-[27px] tracking-tighter text-left'>
//               Modbus Configurations
//             </p>
//           </div>
//           <div className=''>
//             <img src="/images/Bell.png" className='w-[40px] h-[30px] mt-3' alt="" />
//           </div>
//         </div>

//         <div className='mx-6'>
//           <div className='mt-4 grid grid-cols-2 justify-items-start gap-4'>
//             <div className='w-full'>
//               <p className='text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
//                 Select IMEI
//               </p>
//               <div className='mt-2'>
//                 <select
//                   className='block w-full px-4 py-2.5 pr-8 leading-tight bg-[#F8FAFC]'
//                   name="deviceId"
//                   value={formData.deviceId}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Device</option>
//                   {devices.map((device: any) => (
//                     <option key={device.deviceId} value={device.deviceId}>
//                       {device.deviceName} ({device.deviceId})
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ModbusConfig;

import { useState, useEffect } from 'react';
import { http } from '../../../helper/http';
import { useLocation } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from "react-toastify";
import { getValueFromObject } from "../../../common-function/CommonFunction";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface SensorConfig {
  parameterName: string;
  registerType: string;
  registerAddress: string;
  slaveId: string;
  scalingFactor: string;
  modbusDataType: string;
  dataType: string;
  dataSize: string;
}

const ModbusConfig = () => {
    const location = useLocation();
    const deviceId = location.state?.deviceId;
    
    const [sensorConfigs, setSensorConfigs] = useState<SensorConfig[]>([]);

    const [baudRate, setBaudRate] = useState('9600');
    const [stopBits, setStopBits] = useState('1');
    const [parityStatus, setParityStatus] = useState('0');
    const [wordLength, setWordLength] = useState('8');
    const [sleepTime, setSleepTime] = useState('15');
    const [firmwareVersionName, setFirmwareVersionName] = useState('UPC');

    const [parameterName, setParameterName] = useState('');
    const [registerType, setRegisterType] = useState('0');
    const [registerAddress, setRegisterAddress] = useState('40001');
    const [slaveId, setSlaveId] = useState('01');
    const [scalingFactor, setScalingFactor] = useState('0.01');
    const [modbusDataType, setModbusDataType] = useState('1');
    const [dataType, setDataType] = useState('0');
    const [dataSize, setDataSize] = useState('1');

    const [tags, setTags] = useState<any>([]);
    const [deviceName, setDeviceName] = useState('');
    const [currentDevice, setCurrentDevice] = useState<any>([]);
    const [open, setOpen] = useState(false);
    
    const baseUrl = process.env.REACT_APP_BASE_URL;
  
    const fetchTagBasedOnDeviceID = async (deviceId: number) => {
      try {
        const response = (await http(`${baseUrl}/tags?deviceId=${deviceId}`)) as any;
        const current_device = (await http(`${baseUrl}/device?id=${deviceId}`)) as any;
        setTags(response.tags);           
        setCurrentDevice(current_device.devices); 
        setDeviceName(current_device.devices.deviceName);            
      } catch (error) {
        console.error('Error fetching device data:', error);
      }
    };

    useEffect(()=>{
      fetchTagBasedOnDeviceID(deviceId);
    }, [deviceId]);
  
    const handleAddClick = () => {
      if(parameterName &&
        registerType &&
        registerAddress &&
        slaveId &&
        scalingFactor &&
        modbusDataType &&
        dataType &&
        dataSize){
          const sc  = sensorConfigs.find((d: any)=> d.parameterName === parameterName || d.registerAddress === registerAddress);
          if(sc){
            toast.error('Duplicate parameter or register address.')
            return;
          }
          const newConfig: SensorConfig = {
            parameterName,
            registerType,
            registerAddress,
            slaveId,
            scalingFactor,
            modbusDataType,
            dataType,
            dataSize
          };
          setSensorConfigs([...sensorConfigs, newConfig]);
        }else{
          toast.error('All fields are required.');
        }      
    };

    const handleDelete = (index: number) => {
      const newConfigs = [...sensorConfigs];
      newConfigs.splice(index, 1);
      setSensorConfigs(newConfigs);
    }

    const handleReset = () => {
      setBaudRate('9600');
      setStopBits('1');
      setParityStatus('0');
      setWordLength('8');
      setSleepTime('15');
      setFirmwareVersionName('UPC');
    }

    const handleClickOpen = () => {
      if(!sensorConfigs.length){
        return
      }
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleConfigSubmit = async () => {
      if(!sensorConfigs.length){
        return
      }
      
      const modbusConfig = {
        "buad_rate": baudRate,
        "Stop_bits": stopBits,
        "parity": parityStatus,
        "word_length": wordLength
      };

      const deviceData: any = {};
      sensorConfigs.forEach((item, index) => {
        deviceData[`MOD_${index + 1}`] = {
          CID: index,
          Param_Name: item.parameterName,
          Modbus_Slave_Addr: item.slaveId,
          Modbus_Reg_Type: item.registerType,
          Reg_Start: item.registerAddress,
          Reg_Size: item.dataSize,
          Data_Type: item.dataType,
          Modbus_Data_Type: item.modbusDataType,
          Unit: "0",
          Instance_Offset: "0",
          Access_Mode: "22",
          Scaling_Factor: item.scalingFactor,
        };
      });

      let message = {
        "Modbus_config": modbusConfig,
        "deviceData": deviceData,
        "Modcount": sensorConfigs.length,
        "sleeptime": sleepTime,
        "Firm_Ver": firmwareVersionName
      }

      try{
        console.log(currentDevice.deviceId, message);
        setOpen(false);
        const messageString = JSON.stringify(message);
        const resp: any = await http('/modbus-config', 'POST', { deviceId: currentDevice.deviceId, message: messageString });
        toast.success(resp.message);
      }catch(err:any){
        toast.error(err);
      }
        
    }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <ReportProblemIcon color='warning'/> Caution
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Proceeding with the update will overwrite all existing Modbus configuration details. Please ensure that the new settings are accurate before proceeding, as previous configurations will be permanently replaced.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleConfigSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
      <div className='w-full'>

        <h1 className='mx-6' style={{ fontSize: "28px" }}>Modbus Configurations</h1>

        <div className='mx-6'>

          <div className='mt-4 grid grid-cols-2 justify-items-start gap-4'>
            <div className='w-full'>
              <p className='text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                IMEI
              </p>
              <div className='mt-2'>
                <p className='block w-full px-3 py-3 pr-8 leading-tight bg-[#F8FAFC]'>
                  {currentDevice.deviceId}
                </p>
              </div>
            </div>
          
            <div className='w-full'>

              <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                Device Name
              </p>

              <div className='mt-2'>
                <p className='block w-full px-3 py-3 pr-8 leading-tight bg-[#F8FAFC]'>
                  {deviceName || 'device name'}
                </p>
              </div>
            </div>

          </div>

          <div className='flex flex-wrap justify-between items-center gap-4 mt-4'>

            <div className='flex gap-4 mb-3'>
              <img src="/images/Loading.png" className='w-[40px] h-[30px] mt-1' alt="" />
              <span className="font-inter font-medium text-[#393939] text-base md:text-lg lg:text-xl leading-8 md:leading-9 lg:leading-10">
                Last update at 12 April 2024 12:33:43
              </span>
            </div>
            
            <div className="flex space-x-4 mb-3">
              <div className="relative w-[62] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-[blue] cursor-pointer">
                <span className='text-white'>Retrieve</span>
              </div>
              <div className="relative w-[62] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-[green] cursor-pointer" onClick={handleClickOpen}>
                <span className='text-white'>Submit Config</span>
              </div>
            </div>

          </div>

          <div className='flex flex-wrap justify-items-start gap-4'>
            <div className='col-md-6 border h-[362px] p-2'>
              <div className="flex flex-col">
                <div className="conf1">
                  <div className="flex justify-between items-center">
                    <div className="200 p-4 rounded-md">
                      <div className="relative w-full h-[36px] font-inter text-xl font-semibold leading-5 tracking-wider text-left text-gray-800">
                        Modbus Config
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="relative w-[51] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-gray-300 cursor-pointer" onClick={handleReset}>
                        Reset
                      </div>
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-2 justify-items-start  gap-2'>
                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Baud Rate
                    </p>

                    <div className='mt-2'>
                      <input type="text" onChange={(e) => setBaudRate(e.target.value)} value={baudRate} className=' w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]'/>
                    </div>

                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Stop Bits
                    </p>

                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' onChange={(e) => setStopBits(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    </div>
                  </div>

                </div>

                <div className='mt-4 grid grid-cols-2 justify-items-start  gap-4'>
                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Parity Status
                    </p>

                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' onChange={(e) => setParityStatus(e.target.value)}>
                        <option value="0">None (0)</option>
                        <option value="1">Odd(1)</option>
                        <option value="2">Even (2)</option>
                      </select>
                    </div>

                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Word Length
                    </p>
                    <div className='mt-2'>
                      <input type='text' value={wordLength} onChange={(e) => setWordLength(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' />
                    </div>

                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Sleep Time (Minutes)
                    </p>
                    <div className='mt-2'>
                      <input type='text' value={sleepTime} onChange={(e) => setSleepTime(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' />
                    </div>

                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Firmware Version Name
                    </p>
                    <div className='mt-2'>
                      <input type='text' onChange={(e) => setFirmwareVersionName(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' value={firmwareVersionName}/>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div className='col-md-6 border h-[440px] p-[10px]'>
              <div className="flex flex-col">
                <div className="conf1">
                  <div className="flex justify-between items-center">
                    <div className="p-4 rounded-md">
                      <div className="relative w-full h-[36px] font-inter text-xl font-semibold leading-5 tracking-wider text-left text-gray-800">
                        Sensor Config
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="relative w-[62] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-[#0777A7] " onClick={handleAddClick}>
                        <span className='text-white cursor-pointer'>+ Add</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-4 grid grid-cols-2 justify-items-start  gap-2'>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Parameter Name
                    </p>
                
                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' value={parameterName} onChange={(e) => setParameterName(e.target.value)}>
                        <option value="">Select parameter name</option>
                      {tags.map((tag: any) => (
                        <option key={tag.Tag.id} value={tag.Tag.tagname}>{tag.Tag.tagname}</option>
                      ))}
                      </select>
                    </div>
                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Register Type
                    </p>
                
                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' value={registerType} onChange={(e) => setRegisterType(e.target.value)}>
                        {registerTypeData.map((d: any) => (
                          <option key={d.id} value={d.id}>{d.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                </div>

                <div className='mt-4 grid grid-cols-2 justify-items-start  gap-2'>

                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Register Address
                    </p>

                    <div className='mt-2'>
                      <input type='text' value={registerAddress} onChange={(e) => setRegisterAddress(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' />
                    </div>

                  </div>

                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Slave ID
                    </p>

                    <div className='mt-2'>
                      <input type='text' value={slaveId} onChange={(e) => setSlaveId(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' />
                    </div>

                  </div>
                </div>

                <div className='grid grid-cols-2 justify-items-start  gap-2'>

                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Scaling Factor
                    </p>

                    <div className='mt-2'>
                      <input type='text' value={scalingFactor} onChange={(e) => setScalingFactor(e.target.value)} className='block w-full px-3 py-3 pr-8 leading-tight  bg-[#F8FAFC]' />
                    </div>

                  </div>

                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Modbus Data Type
                    </p>

                    <div className='mt-2' style={{ width: '230px', maxWidth: '100%' }}>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' value={modbusDataType} onChange={(e) => setModbusDataType(e.target.value)}>
                        {modbusDataTypeData.map((d:any)=>(
                          <option key={d.id} value={d.id}>{d.name}</option>
                        ))}
                      </select>
                    </div>

                  </div>

                </div>

                <div className='mt-4 grid grid-cols-2 justify-items-start  gap-4'>
                  <div className=' w-full '>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Data Type
                    </p>

                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' value={dataType} onChange={(e) => setDataType(e.target.value)}>
                        {dataTypeData.map((d:any)=>(
                          <option key={d.id} value={d.id}>{d.name}</option>
                        ))}
                      </select>
                    </div>

                  </div>

                  <div className='w-full'>

                    <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                      Data Size
                    </p>

                    <div className='mt-2'>
                      <select className='block w-full px-4 py-2.5 pr-8 leading-tight  bg-[#F8FAFC]' value={dataSize} onChange={(e) => setDataSize(e.target.value)}>
                        {dataSizeData.map((d: any)=>(
                          <option value={d.id}>{d.name}</option>
                        ))}
                      </select>
                    </div>
                    
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div>

          </div>

          <div className='border mt-5 grid grid-rows-2 justify-items-start gap-4' style={{ overflowX: "auto" }}>
            <div className='w-full '>

              <div className="relative w-full h-[36px] p-2 font-inter text-l font leading-5 tracking-wider text-left text-gray-800">
                Overall Summary
              </div>
              <div className="App">
                <div className='p-2'>
                  <table className='border p-2 w-full' style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                    <tr className='border h-[30px] bg-[#0369a1]'>
                      <th className='border'> <span className='text-white'>Parameter Name</span></th>
                      <th className='border'> <span className='text-white'>Register Type</span></th>
                      <th className='border'> <span className='text-white'>Register Address</span></th>
                      <th className='border'> <span className='text-white'>Slave ID</span></th>
                      <th className='border'> <span className='text-white'>Scaling Factor</span></th>
                      <th className='border'> <span className='text-white'>Modbus Data Type</span></th>
                      <th className='border'> <span className='text-white'>Data Type</span></th>
                      <th className='border'> <span className='text-white'>Data Size</span></th>
                      <th className='border'> <span className='text-white'>Actions</span></th>
                    </tr>
                    <tbody>
                      {sensorConfigs.map((config: any, index) => (
                        <tr className='font-inter' key={index}>
                          <td className='border'>{config.parameterName}</td>
                          <td className='border'>{getValueFromObject(registerTypeData, config.registerType)}</td>
                          <td className='border'>{config.registerAddress}</td>
                          <td className='border'>{config.slaveId}</td>
                          <td className='border'>{config.scalingFactor}</td>
                          <td className='border'>{getValueFromObject(modbusDataTypeData, config.modbusDataType)}</td>
                          <td className='border'>{getValueFromObject(dataTypeData, config.dataType)}</td>
                          <td className='border'>{getValueFromObject(dataSizeData, config.dataSize)}</td>
                          <td className='border'><ClearIcon cursor="pointer" onClick={() => handleDelete(index)}/></td>
                        </tr>
                      ))}

                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

const registerTypeData = [
  {
    'id': 0,
    'name': 'Holding Register'
  },
  {
    'id': 1,
    'name': 'Input Register'
  },
  {
    'id': 2,
    'name': 'Coil Status'
  }
];

const modbusDataTypeData = [
  {
    'id': 1,
    'name': '16 bits signed Integer (AB)'
  },
  {
    'id': 2,
    'name': '16 bits unsigned Integer (AB)'
  },
  {
    'id': 3,
    'name': '32 bits signed Integer (ABCD)'
  },
  {
    'id': 4,
    'name': '32 bits unsigned Integer (ABCD)'
  },
  {
    'id': 5,
    'name': '32 bits Float format (ABCD)'
  },
  {
    'id': 6,
    'name': '32 bits signed Integer (CDAB)'
  },
  {
    'id': 7,
    'name': '32 bits unsigned Integer (CDAB)'
  },
  {
    'id': 8,
    'name': '32 bits Float format (CDAB)'
  }
];

const dataTypeData = [
  {
    'id': 0,
    'name': 'PARAM_TYPE_U8'
  },
  {
    'id': 1,
    'name': 'PARAM_TYPE_U16'
  },
  {
    'id': 2,
    'name': 'PARAM_TYPE_U32'
  },
  {
    'id': 3,
    'name': 'PARAM_TYPE_FLOAT'
  }
];

const dataSizeData = [
  {
    'id': 1,
    'name': 'PARAM_TYPE_U8'
  },
  {
    'id': 2,
    'name': 'PARAM_TYPE_U16'
  },
  {
    'id': 4,
    'name': 'PARAM_TYPE_U32'
  },
  {
    'id': 4,
    'name': 'PARAM_TYPE_FLOAT'
  }
];



export default ModbusConfig