import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router-dom";
import frinsoLogo from "../../images/Atharv.jpeg";
import GridViewIcon from "@mui/icons-material/GridView";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import SensorsIcon from "@mui/icons-material/Sensors";
import { useDarkMode } from "../../utils/darkMode/DarkModeContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ProtectedComponent from "../../utils/protected-route/ProtectedComponent";
import { BiLogOut } from "react-icons/bi";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { logout } from "../../app-context/auth-context/AuthAction";
import { http } from "../../helper/http";
import ReportIcon from "@mui/icons-material/Report";
import { getReportAll } from "../../common-function/Report";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Sidebar2 = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 700 });
  const { darkMode } = useDarkMode();
  const sidebar = useRef<any>(null);
  const { dispatch, users } = useUserState();
  const navigate = useNavigate();

  const logoutHandler = () => {
    logout(dispatch);
  };

  const handleDownload = async () => {
    try {
      const response = (await http(
        baseUrl + "/report?customerId=" + users?.user.customerId
      )) as any; // Adjust the URL as needed
      // generatePDFReport(response, users?.user?.name || "");
      getReportAll(response, users?.user?.name || "");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReport = () => {
    // Perform any necessary actions before navigating, such as data fetching

    // Navigate to the desired route
    navigate("/reports/" + users.user.customerId);
  };



  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-50 flex h-screen flex-col overflow-y-hidden duration-300 opacity-100 ease-linear lg:static lg:translate-x-0 shadow-md ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isSmallScreen ? "w-[70%]" : "w-[18%]"} ${darkMode ? "bg-[#364455]" : "bg-white"
        }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex flex-row items-center justify-between gap-2 px-6 py-5 lg:py-6">
        <NavLink
          to="/"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={frinsoLogo}
            alt="Logo"
            style={{ width: "80%", borderRadius: "8%", marginTop: "2px" }}
          />
        </NavLink>
        <IconButton
          onClick={() => setSidebarOpen(!sidebarOpen)}
          sx={{ display: { md: "none" } }}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>

      {/* <!-- SIDEBAR HEADER --> */}
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-9 py-4 px-4 lg:mt-9 lg:px-6">



          {/* <!-- Menu Group --> */}
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
              MENU
            </h3>

            <ul className="mb-6 flex flex-col gap-1.5">
            <ProtectedComponent roleids={[2]}>
  <NavLink
    to="/"
    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${
      darkMode? "text-white" : "text-black"
    } `}
  >
    <GridViewIcon />
    Overview
  </NavLink>
</ProtectedComponent>

              {/* <!-- Menu Item Dashboard Starts here --> */}
              {/* <NavLink
                to="/dashBoard"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${
                  darkMode ? "text-white" : "text-black"
                } `}
              >
                <GridViewIcon />
                Dashboard
              </NavLink> */}

              {/* <!-- Menu Item Dashboard Ends here--> */}

              {/* <!-- Menu Item table Starts here--> */}

              <ProtectedComponent>
                <NavLink
                  to="/customer"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium  duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <SwitchAccountIcon />
                  Customers
                </NavLink>
              </ProtectedComponent>

              <ProtectedComponent>
                <NavLink
                  to="/users"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium  duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <SwitchAccountIcon />
                  Users
                </NavLink>
              </ProtectedComponent>

              {/* <ProtectedComponent> */}
              <NavLink
                to="/device"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                  }`}
              >
                <DeviceHubIcon />
                Devices
              </NavLink>
              {/* </ProtectedComponent> */}

              <ProtectedComponent>
                <NavLink
                  to="/tags"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <SensorsIcon />
                  Tags
                </NavLink>
              </ProtectedComponent>

              <ProtectedComponent>
                <NavLink
                  to="/template"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <DeviceHubIcon />
                  Template
                </NavLink>
              </ProtectedComponent>

              <ProtectedComponent>
                <NavLink
                  to="/tag-group"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <AccountTreeIcon />
                  Tag Group
                </NavLink>
              </ProtectedComponent>

              {(+users.user.id === 17 || +users.user.id===122) && (
                <ProtectedComponent roleids={[2]}>
                  <NavLink to={`/reports1/${users.user.customerId}`}
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                      }`}
                  >
                    <ReportIcon />
                    Report
                  </NavLink>
                </ProtectedComponent>
              )}
              {!(+users.user.id === 17 || +users.user.id === 122) && (
                <NavLink
                  to={`/reports/${users.user.customerId}`}
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <ReportIcon />
                  Report
                </NavLink>
              )}

              <ProtectedComponent>
                <NavLink
                  to="/AssignDevice"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium  duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode ? "text-white" : "text-black"
                    }`}
                >
                  <SwitchAccountIcon />
                  Assign Device
                </NavLink>
              </ProtectedComponent>

              {/* <ProtectedComponent roleids={[2]}>
                <button
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${
                    darkMode ? "text-white" : "text-black"
                  }`}
                  onClick={handleReport}
                >
                  <ReportIcon />
                  Report
                </button>
              </ProtectedComponent> */}

              <NavLink
                to="/alert"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${
                  darkMode ? "text-white" : "text-black"
                }`}
              >
                <AddAlertIcon />
                Alert
              </NavLink>

              {/* <NavLink
                to="/whitelevel"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${
                  darkMode ? "text-white" : "text-black"
                }`}
              >
                <SupervisedUserCircleIcon />
                White Level
              </NavLink> */}



              {/* <!-- Menu Item table Ends here--> */}

              {/* <!-- Menu Item Settings Starts here--> */}
              {/* <li>
                <NavLink
                  to="/settings"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#8a89ac36] ${darkMode? 'text-white':'text-black'}`}
                >
                  <SettingsOutlinedIcon/>
                  Settings
                </NavLink>
              </li> */}
              {/* <!-- Menu Item Settings Ends here--> */}
            </ul>
          </div>
        </nav>
        <div className="absolute bottom-0 w-full h-[80px] lg:hidden">
          <button
            onClick={logoutHandler}
            className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            <BiLogOut />
            Log Out
          </button>
        </div>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar2;
