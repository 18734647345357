// import { useEffect, useState, useMemo } from "react";
// import Table from "@mui/material/Table";
// import Box from "@mui/material/Box";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import LongMenu from "../../../utils/longMenu/LongMenu";
// import { useDeviceState } from "../../../app-context/device-context/DeviceState";
// import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
// import { EnhancedTableHead } from "./EnhancedTableHeader";
// import {
//   getDevicePageClick,
//   setDevice,
// } from "../../../app-context/device-context/DeviceAction";
// import { Device, DeviceApiResponse } from "../../../types/DeviceTypes";
// import AddDeviceFormModal from "./AddDeviceFormModal";
// import { http } from "../../../helper/http";
// import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
// import { Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
// import MqttPublish from "./MqttPublish";
// import { useUserState } from "../../../app-context/auth-context/AuthState";
// import HwraDetails from "./HwraDetails";
// import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
// import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
// import { ApiResponseGetAllCustomer } from "../../../types/CustomerTypes";
// import {
//   fetchAllCustomer,
//   fetchAllTemplate,
// } from "../../../common-function/CommonFunction";
// import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
// import { useTemplateState } from "../../../app-context/template-context/TemplateState";

// const baseUrl = process.env.REACT_APP_BASE_URL;
// export const Devices = () => {
//   const { devices, dispatch } = useDeviceState();
//   const [page] = useState(0);
//   const [updatedRows, setUpdatedRows] = useState<Device[]>([]);
//   const [editModal, setEditModal] = useState(false);
//   const [deviceData, setDeviceData] = useState<Device[]>([]);

//   const [viewModal, setViewModal] = useState(false);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [deleteId, setDeleteId] = useState<number | null>(null);
//   const [publishDeviceId, setPublishDeviceId]: any = useState(null);
//   const { users }: any = useUserState();
//   const [hwraDeviceId, setHwraDeviceId]: any = useState(null);

//   const navigate = useNavigate();
//   const { dispatch: customerDispatch } = useCustomerState();
//   const { dispatch: templateDispatch } = useTemplateState();
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [assignDevice, setAssignDevice] = useState<Device[]>([]);



//   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const newRowsPerPage = parseInt(event.target.value, 10);
//     setRowsPerPage(newRowsPerPage);
//     // Reset to first page when changing rows per page
//     handlePageChange(0);
//   };


//   const toggleDeviceForm = () => {
//     setEditModal((prevVal) => !prevVal);
//   };

//   const toggleViewForm = () => {
//     setViewModal(!viewModal);
//   };

//   useEffect(() => {
//     setUpdatedRows(devices.devices);
//     console.log("my users");
// console.log(users);

//     fetchData();
//   }, [devices, devices.devices]);



//   const fetchData = async () => {
//     try {
//       const resp: any = await http(`${baseUrl}/assign-device?userId=${users.user.id}`);
//       console.log("i am from device");
//       console.log(resp.devices.devices);
//       setAssignDevice(resp.devices.devices);
//       const fetchedDevices = resp.devices.devices.map((item: any) => item.Device);
//       setUpdatedRows([...devices.devices, ...fetchedDevices]);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };


//   const handleMenuOptionClick = async (option: string, id: number) => {
//     try {
//       let _device: any;
//       switch (option) {
//         case "Delete":
//           setShowConfirmModal(true);
//           setDeleteId(id);
//           break;
//         case "Edit":
//           const resp = (await fetchAllCustomer()) as any;
//           setAllCustomer(customerDispatch, resp.customers);
//           const respTemplate = (await fetchAllTemplate()) as any;
//           setAllTemplate(templateDispatch, respTemplate.template);
//           const editResp = (await http(
//             baseUrl + `/device?id=${id}`
//           )) as DeviceApiResponse;
//           setDeviceData([editResp.devices]);
//           toggleDeviceForm();
//           break;
//         case "View":
//           const viewResp = (await http(
//             baseUrl + `/device?id=${id}`
//           )) as DeviceApiResponse;
//           setDeviceData([viewResp.devices]);
//           toggleViewForm();
//           break;
//         case "Data":
//           navigate("/data", { state: { deviceId: id } });
//           break;
//         case "ModbusConfig":
//           navigate("/modbusConfig", { state: { deviceId: id } });
//           break;
//         case "Publish":
//           _device = devices.devices.find((d: any) => d.id === id);
//           setPublishDeviceId(_device.deviceId);
//           break;
//         case "HWRA":
//           setHwraDeviceId(id);
//           break;
//         case "CSV Upload":
//           navigate("csv-upload/" + id);
//           break;
//         default:
//           break;
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   console.log(devices, "ll");

//   const handleConfirmDelete = async () => {
//     if (deleteId !== null) {
//       try {
//         const deleteResp = (await http(
//           baseUrl + `/device?id=${deleteId}`,
//           "DELETE"
//         )) as DeviceApiResponse;
//         toast.success(deleteResp.message);
//         if (devices.devices.length === 1) {
//           setDevice(dispatch, 1);
//         } else {
//           setDevice(dispatch, devices.currentPage);
//         }
//       } catch (err) {
//         console.log(err);
//       }
//       setShowConfirmModal(false);
//     }
//   };

//   const handlePageChange = async (newPage: number) => {
//     const direction = newPage > devices.currentPage - 1 ? "next" : "previous";
//     const page = +devices.currentPage;
//     try {
//       const resp = (await http(
//         baseUrl + `/device?page=${direction === "next" ? page + 1 : page - 1}&limit=${rowsPerPage}`
//       )) as DeviceApiResponse;
//       getDevicePageClick(dispatch, resp.devices);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const emptyRows = 10 - updatedRows.length;
//   const visibleRows = useMemo(
//     () =>
//       updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
//     [page, rowsPerPage, updatedRows]
//   );


//   return (
//     <>
//       <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
//         <Paper sx={{ width: "99%" }}>
//           <EnhancedTableToolbar />
//           <div className="h-[72vh] overflow-y-auto">
//             <TableContainer>
//               <ProtectedComponent>
//                 {showConfirmModal && (
//                   <ConfirmModal
//                     title="Confirmation"
//                     message="Are you sure you want to delete?"
//                     onConfirm={handleConfirmDelete}
//                     onCancel={() => {
//                       setShowConfirmModal(false);
//                     }}
//                     isVisible={showConfirmModal}
//                   />
//                 )}
//               </ProtectedComponent>

//               <ProtectedComponent>
//                 {editModal && (
//                   <AddDeviceFormModal
//                     uniqueId="EDIT"
//                     data={deviceData}
//                     toggleForm={editModal}
//                     toggleFormFun={toggleDeviceForm}
//                   />
//                 )}
//               </ProtectedComponent>

//               <Table
//                 className="min-w-750 bg-white"
//                 aria-labelledby="tableTitle"
//                 size="medium"
//               >


//                 <EnhancedTableHead />
//                 <TableBody>
//                   {visibleRows.map((row: Device) => (

//                     <TableRow
//                       key={row?.id || null}
//                       hover
//                       className="cursor-pointer transition-all hover:bg-gray-100"
//                     >
//                       {row && (

//                         <>
//                           {/* {console.log("row", row)} */}
//                           <TableCell
//                             className="text-center"
//                             style={{ textAlign: "center", padding: "10px" }}
//                           >
//                             {row.deviceId}
//                           </TableCell>

//                           <TableCell
//                             className="text-center"
//                             style={{ textAlign: "center" }}
//                           >
//                             {row.deviceName}
//                           </TableCell>

//                           <ProtectedComponent>
//                             <TableCell
//                               className="text-center"
//                               style={{ textAlign: "center" }}
//                             >
//                               {/* {row?.customerName || ""} */}
//                               {row?.Customer?.name || "NA"}
//                             </TableCell>
//                           </ProtectedComponent>

//                           <TableCell
//                             className="text-center"
//                             style={{ textAlign: "center" }}
//                           >
//                             {row.latitude},{row.longitude}
//                           </TableCell>

//                           <TableCell
//                             className="text-center"
//                             style={{ textAlign: "center" }}
//                           >
//                             <span
//                               className={`text-${row.status ? 'green-600' : 'red-600'} 
//                             ${row.status ? 'bg-green-200' : 'bg-red-100'} 
//                             rounded-md 
//                             ${row.status ? 'px-4 py-2' : 'px-3 py-2'} `}>
//                               {row.status ? 'Active' : 'Inactive'}
//                             </span>
//                           </TableCell>
//                           <ProtectedComponent>
//                             <TableCell
//                               className="text-center"
//                               style={{ textAlign: "center" }}
//                             >
//                               <LongMenu
//                                 options={[
//                                   "Edit",
//                                   "Delete",
//                                   "Data",
//                                   "Publish",
//                                   "HWRA",
//                                   "CSV Upload",
//                                 ]}
//                                 onOptionClick={handleMenuOptionClick}
//                                 id={row.id}
//                               />
//                             </TableCell>
//                           </ProtectedComponent>

//                           <ProtectedComponent roleids={[2]}>
//                             <TableCell
//                               className="text-center"
//                               style={{ textAlign: "center" }}
//                             >
//                               <LongMenu
//                                 // options={
//                                 //   users.user.id == 17
//                                 //     ? ["Data", "Publish"]
//                                 //     : ["Data" ,"ModbusConfig"]
//                                 // }


//                                 options={
//                                   ["Data",row.modbusConfig===false?'': "ModbusConfig"]
//                                 }
//                                 onOptionClick={handleMenuOptionClick}
//                                 id={row.id}
//                               />
//                             </TableCell>
//                           </ProtectedComponent>
//                         </>
//                       )}
//                     </TableRow>
//                   ))}

//                   {/* {emptyRows > 0 && (
//                     <TableRow
//                       style={{
//                         height: 45 * emptyRows,
//                       }}
//                     >
//                       <TableCell colSpan={4} />
//                     </TableRow>
//                   )} */}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>
//           <TablePagination
//   component="div"
//   rowsPerPageOptions={[5, 50, 100]}
//   count={devices?.totalItems || 0}
//   rowsPerPage={rowsPerPage}
//   page={Math.max(
//     0,
//     Math.min(devices?.currentPage - 1, devices?.totalPages - 1)
//   )}
//   onPageChange={(event, newPage) => handlePageChange(newPage)}
//   onRowsPerPageChange={handleChangeRowsPerPage}
//   className="bg-gray-100"
// />
//         </Paper>
//       </Box>

//       <Dialog open={!!publishDeviceId} onClose={() => setPublishDeviceId(null)}>
//         <DialogTitle>Publish Message</DialogTitle>
//         <DialogContent>
//           <MqttPublish deviceId={publishDeviceId} />
//         </DialogContent>
//       </Dialog>

//       <Dialog open={hwraDeviceId} onClose={() => setHwraDeviceId(null)}>
//         <DialogTitle>HWRA Details</DialogTitle>
//         <DialogContent>
//           <HwraDetails deviceId={hwraDeviceId} />
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };



import { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHeader";
import {
  getDevicePageClick,
  setDevice,
} from "../../../app-context/device-context/DeviceAction";
import { Device, DeviceApiResponse } from "../../../types/DeviceTypes";
import AddDeviceFormModal from "./AddDeviceFormModal";
import { http } from "../../../helper/http";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
import MqttPublish from "./MqttPublish";
import { useUserState } from "../../../app-context/auth-context/AuthState";
import HwraDetails from "./HwraDetails";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { ApiResponseGetAllCustomer } from "../../../types/CustomerTypes";
import {
  fetchAllCustomer,
  fetchAllTemplate,
} from "../../../common-function/CommonFunction";
import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import DeviceTable1 from "./device-table1/DeviceTable1";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const Devices = () => {
  const { devices, dispatch } = useDeviceState();
  const [page] = useState(0);
  const [updatedRows, setUpdatedRows] = useState<Device[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [deviceData, setDeviceData] = useState<Device[]>([]);
  const [rowsPerPage] = useState(10);
  const [viewModal, setViewModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [publishDeviceId, setPublishDeviceId]: any = useState(null);
  const { users }: any = useUserState();
  const [hwraDeviceId, setHwraDeviceId]: any = useState(null);
  const [combinedDevices, setCombinedDevices] = useState<any[]>([]);

  const navigate = useNavigate();
  const { dispatch: customerDispatch } = useCustomerState();
  const { dispatch: templateDispatch } = useTemplateState();

  const [assignDevice, setAssignDevice] = useState<Device[]>([]);






  const toggleDeviceForm = () => {
    setEditModal((prevVal) => !prevVal);
  };

  const toggleViewForm = () => {
    setViewModal(!viewModal);
  };

  useEffect(() => {
    setUpdatedRows(devices.devices);

    fetchData();
  }, [devices, devices.devices]);



  const fetchData = async () => {
    try {
      const resp: any = await http(`${baseUrl}/assign-device?userId=${users.user.id}`);

      setAssignDevice(resp.devices.devices);
      const fetchedDevices = resp.devices.devices.map((item: any) => item.Device);
      setUpdatedRows([...devices.devices, ...fetchedDevices]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      let _device: any;
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const resp = (await fetchAllCustomer()) as any;
          setAllCustomer(customerDispatch, resp.customers);
          const respTemplate = (await fetchAllTemplate()) as any;
          setAllTemplate(templateDispatch, respTemplate.template);
          const editResp = (await http(
            baseUrl + `/device?id=${id}`
          )) as DeviceApiResponse;
          setDeviceData([editResp.devices]);
          toggleDeviceForm();
          break;
        case "View":
          const viewResp = (await http(
            baseUrl + `/device?id=${id}`
          )) as DeviceApiResponse;
          setDeviceData([viewResp.devices]);
          toggleViewForm();
          break;
        case "Data":
          navigate("/data", { state: { deviceId: id } });
          break;
        case "ModbusConfig":
          navigate("/modbusConfig", { state: { deviceId: id } });
          break;
        case "Publish":
          _device = devices.devices.find((d: any) => d.id === id);
          setPublishDeviceId(_device.deviceId);
          break;
        case "HWRA":
          setHwraDeviceId(id);
          break;
        case "CSV Upload":
          navigate("csv-upload/" + id);
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/device?id=${deleteId}`,
          "DELETE"
        )) as DeviceApiResponse;
        toast.success(deleteResp.message);
        if (devices.devices.length === 1) {
          setDevice(dispatch, 1);
        } else {
          setDevice(dispatch, devices.currentPage);
        }
      } catch (err) {
        console.log(err);
      }
      setShowConfirmModal(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > devices.currentPage - 1 ? "next" : "previous";
    const page = +devices.currentPage;
    try {
      const resp = (await http(
        baseUrl + `/device?page=${direction === "next" ? page + 1 : page - 1}`
      )) as DeviceApiResponse;
      getDevicePageClick(dispatch, resp.devices);
    } catch (err) {
      console.log(err);
    }
  };

  const emptyRows = 10 - updatedRows.length;
  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );



  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <ProtectedComponent>
            <EnhancedTableToolbar />
            <div className="h-[72vh] overflow-y-auto">
              <TableContainer>
                <ProtectedComponent>
                  {showConfirmModal && (
                    <ConfirmModal
                      title="Confirmation"
                      message="Are you sure you want to delete?"
                      onConfirm={handleConfirmDelete}
                      onCancel={() => {
                        setShowConfirmModal(false);
                      }}
                      isVisible={showConfirmModal}
                    />
                  )}
                </ProtectedComponent>

                <ProtectedComponent>
                  {editModal && (
                    <AddDeviceFormModal
                      uniqueId="EDIT"
                      data={deviceData}
                      toggleForm={editModal}
                      toggleFormFun={toggleDeviceForm}
                    />
                  )}
                </ProtectedComponent>

                <Table
                  className="min-w-750 bg-white"
                  aria-labelledby="tableTitle"
                  size="medium"
                >


                  <EnhancedTableHead />
                  <TableBody>
                    {visibleRows.map((row: Device) => (

                      <TableRow
                        key={row?.id || null}
                        hover
                        className="cursor-pointer transition-all hover:bg-gray-100"
                      >
                        {row && (

                          <>
                            {/* {console.log("row", row)} */}
                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center", padding: "10px" }}
                            >
                              {row.deviceId}
                            </TableCell>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              {row.deviceName}
                            </TableCell>

                            <ProtectedComponent>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                {/* {row?.customerName || ""} */}
                                {row?.Customer?.name || "NA"}
                              </TableCell>
                            </ProtectedComponent>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              {row.latitude},{row.longitude}
                            </TableCell>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              <span
                                className={`text-${row.status ? 'green-600' : 'red-600'} 
                            ${row.status ? 'bg-green-200' : 'bg-red-100'} 
                            rounded-md 
                            ${row.status ? 'px-4 py-2' : 'px-3 py-2'} `}>
                                {row.status ? 'Active' : 'Inactive'}
                              </span>
                            </TableCell>
                            <ProtectedComponent>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                <LongMenu
                                  options={[
                                    "Edit",
                                    "Delete",
                                    "Data",
                                    "Publish",
                                    "HWRA",
                                    "CSV Upload",
                                  ]}
                                  onOptionClick={handleMenuOptionClick}
                                  id={row.id}
                                />
                              </TableCell>
                            </ProtectedComponent>

                            <ProtectedComponent roleids={[2]}>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                <LongMenu
                                  // options={
                                  //   users.user.id == 17
                                  //     ? ["Data", "Publish"]
                                  //     : ["Data" ,"ModbusConfig"]
                                  // }


                                  options={
                                    ["Data", row.modbusConfig === false ? '' : "ModbusConfig"]
                                  }
                                  onOptionClick={handleMenuOptionClick}
                                  id={row.id}
                                />
                              </TableCell>
                            </ProtectedComponent>
                          </>
                        )}
                      </TableRow>
                    ))}

                    {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 45 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={devices?.totalItems || 0}
              rowsPerPage={10}
              page={Math.max(
                0,
                Math.min(devices?.currentPage - 1, devices?.totalPages - 1)
              )}
              onPageChange={(event, newPage) => handlePageChange(newPage)}
              className="bg-gray-100"
            />
          </ProtectedComponent>
        </Paper>
      </Box>
      <ProtectedComponent roleids={[2]}>

        <DeviceTable1 />
      </ProtectedComponent>
      <Dialog open={!!publishDeviceId} onClose={() => setPublishDeviceId(null)}>
        <DialogTitle>Publish Message</DialogTitle>
        <DialogContent>
          <MqttPublish deviceId={publishDeviceId} />
        </DialogContent>
      </Dialog>

      <Dialog open={hwraDeviceId} onClose={() => setHwraDeviceId(null)}>
        <DialogTitle>HWRA Details</DialogTitle>
        <DialogContent>
          <HwraDetails deviceId={hwraDeviceId} />
        </DialogContent>
      </Dialog>
    </>
  );
};

