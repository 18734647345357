// import jsPDF from "jspdf";
// import { http } from "../helper/http";
// import { ApiResponseGetAllCustomer } from "../types/CustomerTypes";
// import { ApiResponseGetAllTags } from "../types/TagTypes";
// import { ApiResponseGetAllTemplate } from "../types/TemplateType";
// import html2canvas from "html2canvas";
// import frinsologo from "../images/logoblue.jpeg";
// import "jspdf-autotable"; // Import the autoTable plugin

// const baseUrl = process.env.REACT_APP_BASE_URL;
// export const fetchAllCustomer = async () => {
//   try {
//     return (await http(
//       `${baseUrl}/customer?allcustomer=all`
//     )) as ApiResponseGetAllCustomer;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };
// export const fetchAllTemplate = async () => {
//   try {
//     return (await http(
//       `${baseUrl}/templates?alltemplates=all`
//     )) as ApiResponseGetAllTemplate;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

// export const fetchAllTags = async () => {
//   try {
//     return (await http(baseUrl + "/tags?alltags=all")) as ApiResponseGetAllTags;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

// // START FUNCTION TO FIND THE CONSUMTION DATA BASED ON POSITIVE CUMULATIVE
// interface DataItem {
//   timestamp: string;
//   positivecumulative: string;
// }

// interface HourlyData {
//   firstTimeStamp: string;
//   lastTimeStamp: string;
//   firstPositivecumulative: string;
//   lastPositivecumulative: string;
//   hour: string;
// }
// // STEP 1
// // the below function will return array of object of last 24 hour from current
// // the function will return 1sttime stamp data and last time stamp data and
// // [
// //   {
// //     firstTimeStamp: '2024-03-10 01:02:30',
// //     lastTimeStamp: '2024-03-10 01:59:30',
// //     firstPositivecumulative: '383.152',
// //     lastPositivecumulative: '3563.152',
// //     hour: '2024-03-10T01'
// //   },
// //   {
// //     firstTimeStamp: '2024-03-10 07:10:08',
// //     lastTimeStamp: '2024-03-10 07:58:08',
// //     firstPositivecumulative: '3833.152',
// //     lastPositivecumulative: '353.152',
// //     hour: '2024-03-10T07'
// //   },]
// export function filterDataFromEachHourLast24Hours(
//   data: DataItem[]
// ): HourlyData[] {
//   data.sort(
//     (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
//   );

//   // Calculate the timestamp for 24 hours ago from the current time
//   const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

//   // Filter the array to include only items from the last 24 hours from the current hour
//   const last24HoursData = data.filter(
//     (item) => new Date(item.timestamp) >= twentyFourHoursAgo
//   );

//   // Initialize an array to store the results
//   const result: HourlyData[] = [];

//   // Process the data
//   last24HoursData.forEach((item) => {
//     const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

//     const index = result.findIndex(
//       (hourlyData) => hourlyData.hour === hourTimestamp
//     );

//     if (index === -1) {
//       // If the hour doesn't exist in the result array, initialize it
//       result.push({
//         firstTimeStamp: item.timestamp,
//         lastTimeStamp: item.timestamp,
//         firstPositivecumulative: item.positivecumulative,
//         lastPositivecumulative: item.positivecumulative,
//         hour: hourTimestamp,
//       });
//     } else {
//       // Update the lastTimeStamp and lastPositivecumulative for the current hour
//       result[index].lastTimeStamp = item.timestamp;
//       result[index].lastPositivecumulative = item.positivecumulative;
//     }
//   });

//   return result;
// }

// interface HourlyData {
//   firstTimeStamp: string;
//   lastTimeStamp: string;
//   firstPositivecumulative: string;
//   lastPositivecumulative: string;
//   hour: string;
// }
// // STEP 2
// // this function will destructure the array ofHourlyData data based on need it
// export function processHourlyData(inputData: HourlyData[]): HourlyData[] {
//   const processedData: HourlyData[] = [];

//   // Process the first object separately
//   processedData.push({
//     firstTimeStamp: inputData[0].firstTimeStamp,
//     lastTimeStamp: inputData[0].lastTimeStamp,
//     firstPositivecumulative: inputData[0].firstPositivecumulative,
//     lastPositivecumulative: inputData[0].lastPositivecumulative,
//     hour: inputData[0].hour,
//   });

//   // Process the rest of the objects
//   for (let i = 1; i < inputData.length; i++) {
//     const currentHour = inputData[i];
//     const previousHour = inputData[i - 1];

//     processedData.push({
//       firstTimeStamp: previousHour.lastTimeStamp,
//       lastTimeStamp: currentHour.lastTimeStamp,
//       firstPositivecumulative: previousHour.lastPositivecumulative,
//       lastPositivecumulative: currentHour.lastPositivecumulative,
//       hour: currentHour.hour,
//     });
//   }

//   return processedData;
// }

// //  STEP 3

// interface HourlyData {
//   firstTimeStamp: string;
//   lastTimeStamp: string;
//   firstPositivecumulative: string;
//   lastPositivecumulative: string;
//   hour: string;
// }

// interface ConsumptionData {
//   hour: string;
//   consumption: number;
//   firstTimeStamp: string;
//   lastTimeStamp: string;
// }
// // this will return ths consumtion data
// export function processConsumptionData(
//   inputData: HourlyData[]
// ): ConsumptionData[] {
//   const consumptionData: ConsumptionData[] = [];

//   for (let i = 0; i < inputData.length; i++) {
//     const currentHour = inputData[i];

//     const consumption: ConsumptionData = {
//       hour: currentHour.hour,
//       consumption: Math.abs(
//         parseFloat(currentHour.firstPositivecumulative) -
//           parseFloat(currentHour.lastPositivecumulative)
//       ),
//       firstTimeStamp: currentHour.firstTimeStamp,
//       lastTimeStamp: currentHour.lastTimeStamp,
//     };

//     consumptionData.push(consumption);
//   }

//   return consumptionData;
// }

// // END FUNCTION TO FIND THE CONSUMTION DATA BASED ON POSITIVE CUMULATIVE

// export function fillMissingHours(data: any) {
//   const result: {
//     flowStatus: any;
//     hour: string;
//     consumption: number;
//     firstTimeStamp: null;
//     lastTimeStamp: null;
//   }[] = [];
//   const currentDate = new Date(); // Get current date and time

//   // Adjust current date and time to IST
//   const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

//   // Generate the last 24 hours in IST format 'YYYY-MM-DD HH'
//   const last24Hours = [];
//   for (let i = 0; i < 24; i++) {
//     const hourAgo = new Date(currentISTDate.getTime() - i * 60 * 60 * 1000);
//     const formattedHour = hourAgo.toISOString().slice(0, 13).replace("T", " ");
//     last24Hours.push(formattedHour);
//   }

//   // Iterate over the generated hours and check if each hour is present in the data
//   last24Hours.reverse().forEach((hour) => {
//     const foundData = data.find((item: { hour: string }) => item.hour === hour);

//     // If data for the hour is found, add it to the result, otherwise add default values
//     if (foundData) {
//       result.push(foundData);
//     } else {
//       // ./
//     }
//   });

//   return result;
// }

// export const generateAndDownloadPDF = async (
//   consumtionData: any[],
//   chartRef: React.RefObject<HTMLDivElement>,
//   deviceName: string,
//   customerName: string
// ) => {

//   try {
//     const pdf = new jsPDF(); // Create a new PDF instance
//     pdf.setFont("helvetica"); // Set default font
//     pdf.setFontSize(12); // Set default font size

//     // Add Frinso heading
//     pdf.setFontSize(24); // Set font size to 18
//     pdf.text(deviceName, pdf.internal.pageSize.getWidth() / 2, 25, {
//       align: "center",
//     }); // Center the text
//     // Add logo image
//     // const logoWidth = 30; // Adjust the width of the logo image
//     // const logoHeight = 20; // Adjust the height of the logo image
//     // const logoX = (pdf.internal.pageSize.getWidth() - logoWidth) / 2; // Calculate X position to center horizontally
//     // pdf.addImage(img, "JPEG", logoX, 15, logoWidth, logoHeight); // Adjust position and size

//     // Add chart image if available
//     if (chartRef.current) {
//       const canvas = await html2canvas(chartRef.current, {
//         scale: 2, // Increase scale to improve quality
//       });
//       const chartImgData = canvas.toDataURL("image/png");
//       pdf.addImage(chartImgData, "PNG", 15, 50, 180, 100); // Adjust position and size
//     }

//     // Add tabular data using autoTable plugin
//     const tableColumns = [
//       "Hour",
//       "Consumption",
//       "First Time Stamp",
//       "Last Time Stamp",
//     ];
//     const tableRows = consumtionData.map((entry) => [
//       entry.hour,
//       entry.consumption ? entry.consumption.toFixed(2) : "0",
//       entry.firstTimeStamp,
//       entry.lastTimeStamp,
//     ]);
//     (pdf as any).autoTable({
//       // Cast pdf as any to avoid TypeScript error
//       head: [tableColumns],
//       body: tableRows,
//       startY: 165, // Adjust the startY position to leave space below the chart
//     });

//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();

//     const fontSize = 18; // Set font size to 18
//     const fontStyle = "helvetica"; // Set font style

//     // Calculate text width based on font size and content
//     const textWidth =
//       (pdf.getStringUnitWidth(customerName) * fontSize) /
//       pdf.internal.scaleFactor;

//     // Set the X position for the text, move to the right by 20 units from center
//     const xPos = pdfWidth / 2 + textWidth / 2 + 50; // Adjusted to move more to the right

//     // Set the Y position for the text (assuming 10 padding from bottom)
//     const yPos = pdfHeight - 10;

//     // Set font size and font style
//     pdf.setFontSize(fontSize);
//     pdf.setFont(fontStyle);

//     // Add the text
//     pdf.text(customerName, xPos, yPos, { align: "right" });

//     // Save or download the PDF
//     pdf.save("frinso.pdf");
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

// //START WATER QUALITY
// interface TestData {
//   dotemp: string;
//   dovalue: string;
//   phtemp: string;
//   phvalue: string;
//   tdstemp: string;
//   tdsvalue: string;
//   timestamp: string;
// }

// export function filterDataLast24Hours(testData: TestData[]): TestData[] {
//   // Step 1: Get the current date and time in UTC
//   let currentUTCTime = new Date();

//   // Step 2: Convert UTC time to Indian Standard Time (IST)
//   let ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
//   let currentISTTime = new Date(currentUTCTime.getTime() + ISTOffset);

//   // Step 3: Calculate the date and time 24 hours ago in IST
//   let twentyFourHoursAgoIST = new Date(
//     currentISTTime.getTime() - 24 * 60 * 60 * 1000
//   );

//   // Step 4: Filter the data based on timestamps falling within the last 24 hours in IST
//   let filteredDataIST = testData.filter((item) => {
//     let itemTimestamp = new Date(item.timestamp);
//     return (
//       itemTimestamp >= twentyFourHoursAgoIST && itemTimestamp <= currentISTTime
//     );
//   });

//   return filteredDataIST;
// }

// interface TestData {
//   dotemp: string;
//   dovalue: string;
//   phtemp: string;
//   phvalue: string;
//   tdstemp: string;
//   tdsvalue: string;
//   timestamp: string;
// }

// export function groupDataByHour(data: TestData[]): {
//   [hour: number]: TestData;
// } {
//   const groupedData: { [hour: number]: TestData } = {};

//   data.forEach((item) => {
//     const hour = new Date(item.timestamp).getHours();
//     if (
//       !groupedData[hour] ||
//       new Date(groupedData[hour].timestamp) < new Date(item.timestamp)
//     ) {
//       groupedData[hour] = item;
//     }
//   });

//   return groupedData;
// }

// export function getLast24HoursIST() {
//   // Get current date and time in Indian Standard Time (IST)
//   const currentDateIST = new Date(
//     new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
//   );

//   // Initialize an array to store the result
//   const hoursArrayIST = [];

//   // Loop through the last 24 hours
//   for (let i = 0; i < 24; i++) {
//     // Create a new date object for each hour
//     const hourDateIST = new Date(currentDateIST.getTime() - i * 60 * 60 * 1000);

//     // Format the date in the desired format
//     const formattedHourIST = `${hourDateIST.getFullYear()}-${(
//       hourDateIST.getMonth() + 1
//     )
//       .toString()
//       .padStart(2, "0")}-${hourDateIST
//       .getDate()
//       .toString()
//       .padStart(2, "0")} ${hourDateIST
//       .getHours()
//       .toString()
//       .padStart(2, "0")}:00:00`;

//     // Add the formatted hour to the array
//     hoursArrayIST.unshift(formattedHourIST);
//   }

//   // Return the array
//   return hoursArrayIST;
// }

// // END WATER QUALITY

// // export const formatTimestamp = (timestamp: any) => {
// //   const date = new Date(timestamp);
// //   const year = date.getFullYear();
// //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //   const day = date.getDate().toString().padStart(2, "0");
// //   const hours = date.getHours().toString().padStart(2, "0");
// //   const minutes = date.getMinutes().toString().padStart(2, "0");
// //   const seconds = date.getSeconds().toString().padStart(2, "0");

// //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// // };

// export const formatTimestamp = (timestamp: any) => {
//   const date = new Date(timestamp);
//   const year = date.getUTCFullYear();
//   const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
//   const day = date.getUTCDate().toString().padStart(2, "0");
//   const hours = date.getUTCHours().toString().padStart(2, "0");
//   const minutes = date.getUTCMinutes().toString().padStart(2, "0");
//   const seconds = date.getUTCSeconds().toString().padStart(2, "0");

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };

// interface DataItem1 {
//   timestamp: string;
//   totalizer: string;
// }

// interface HourlyData1 {
//   firstTimeStamp: string;
//   lastTimeStamp: string;
//   firstTotalizer: string;
//   lastTotalizer: string;
//   hour: string;
// }
// export function filterDataFromEachHourLast24HoursTotalizer(
//   data: DataItem1[]
// ): HourlyData1[] {
//   data.sort(
//     (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
//   );

//   // Calculate the timestamp for 24 hours ago from the current time
//   const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
//   // Filter the array to include only items from the last 24 hours from the current hour
//   const last24HoursData = data.filter(
//     (item) => new Date(item.timestamp) >= twentyFourHoursAgo
//   );
//   // Initialize an array to store the results
//   const result: HourlyData1[] = [];

//   // Process the data
//   last24HoursData.forEach((item) => {
//     const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

//     const index = result.findIndex(
//       (hourlyData) => hourlyData.hour === hourTimestamp
//     );

//     if (index === -1) {
//       // If the hour doesn't exist in the result array, initialize it
//       result.push({
//         firstTimeStamp: item.timestamp,
//         lastTimeStamp: item.timestamp,
//         firstTotalizer: item.totalizer,
//         lastTotalizer: item.totalizer,
//         hour: hourTimestamp,
//       });
//     } else {
//       // Update the lastTimeStamp and lastPositivecumulative for the current hour
//       result[index].lastTimeStamp = item.timestamp;
//       result[index].lastTotalizer = item.totalizer;
//     }
//   });

//   return result;
// }

// export function processHourlyDataTotalizer(
//   inputData: HourlyData1[]
// ): HourlyData1[] {
//   const processedData: HourlyData1[] = [];

//   // Process the first object separately

//   processedData.push({
//     firstTimeStamp: inputData[0].firstTimeStamp,
//     lastTimeStamp: inputData[0].lastTimeStamp,
//     firstTotalizer: inputData[0].firstTotalizer,
//     lastTotalizer: inputData[0].lastTotalizer,
//     hour: inputData[0].hour,
//   });
//   // console.log(processedData, "proces");

//   // Process the rest of the objects
//   for (let i = 1; i < inputData.length; i++) {
//     const currentHour = inputData[i];
//     const previousHour = inputData[i - 1];

//     processedData.push({
//       firstTimeStamp: previousHour.lastTimeStamp,
//       lastTimeStamp: currentHour.lastTimeStamp,
//       firstTotalizer: previousHour.lastTotalizer,
//       lastTotalizer: currentHour.lastTotalizer,
//       hour: currentHour.hour,
//     });
//   }

//   return processedData;
// }

// interface ConsumptionData1 {
//   hour: string;
//   consumption: number;
//   firstTimeStamp: string;
//   lastTimeStamp: string;
// }
// // this will return ths consumtion data
// export function processConsumptionDataTotalizer(
//   inputData: HourlyData1[]
// ): ConsumptionData1[] {
//   const consumptionData: ConsumptionData1[] = [];

//   for (let i = 0; i < inputData.length; i++) {
//     const currentHour = inputData[i];

//     let c = +currentHour.firstTotalizer - +currentHour.lastTotalizer;

//     if (c < 0) {
//       c = c * -1;
//     }

//     const consumption: ConsumptionData = {
//       hour: currentHour.hour,
//       consumption: c,
//       firstTimeStamp: currentHour.firstTimeStamp,
//       lastTimeStamp: currentHour.lastTimeStamp,
//     };

//     consumptionData.push(consumption);
//   }

//   return consumptionData;
// }

// export function getIndianCurrentTime() {
//   let currentUTCTime = new Date();

//   // Convert UTC time to Indian Standard Time (IST)
//   let ISTOffset = 330; // Offset in minutes for IST (5 hours 30 minutes)
//   let indianTime = new Date(currentUTCTime.getTime() + ISTOffset * 60000);

//   // Format the Indian Standard Time (IST)
//   let formattedISTTime = indianTime
//     .toISOString()
//     .slice(0, 19)
//     .replace("T", " ");
//   return formattedISTTime;
// }

// // export function generateCSV(columns: string[], data: any[]) {
// //   // Prepare CSV content
// //   let csvContent = "";

// //   // Convert column names to lowercase for comparison
// //   const lowercaseColumns = columns
// //     .filter((column) => !column.includes("#")) // Filter out columns containing #
// //     .map((column) => column.toLowerCase()); // Convert remaining columns to lowercase

// //   // Add header row with column names
// //   csvContent += lowercaseColumns.join(",") + "\n";

// //   // Add data rows
// //   data.forEach((item: any) => {
// //     const row = lowercaseColumns.map((column: string) => {
// //       const matchingKey = Object.keys(item).find(
// //         (key) => key.toLowerCase() === column
// //       );
// //       return matchingKey !== undefined ? item[matchingKey] : "";
// //     });
// //     csvContent += row.join(",") + "\n";
// //   });

// //   // Create a Blob containing the CSV data
// //   const blob = new Blob([csvContent], { type: "text/csv" });

// //   // Create a temporary anchor element to trigger the download
// //   const link = document.createElement("a");
// //   link.href = URL.createObjectURL(blob);
// //   link.download = "data.csv";
// //   link.click();
// // }

// export function generateCSV(columns: string[], data: any[]) {
//   // Prepare CSV content
//   let csvContent = "";

//   // Add header row with column names
//   csvContent += columns.join(",") + "\n";

//   // Add data rows
//   data.forEach((item: any) => {
//     const row = columns.map((column: string) => {
//       // Handle cases where the column name includes "#"
//       const matchingKey = Object.keys(item).find(
//         (key) => key.toLowerCase() === column.toLowerCase()
//       );
//       return matchingKey !== undefined ? item[matchingKey] : "";
//     });
//     csvContent += row.join(",") + "\n";
//   });

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvContent], { type: "text/csv" });

//   // Create a temporary anchor element to trigger the download
//   const link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "data.csv";
//   link.click();
// }

// export function generateCSV1(data: any[]) {
//   if (data.length === 0) {
//     console.error("Data array is empty");
//     return;
//   }

//   // Extract column names from the keys of the first item in the data array
//   const columns = Object.keys(data[0]).filter((key) => key !== "logId");

//   // Prepare CSV content
//   let csvContent = "";

//   // Add header row with column names
//   csvContent += columns.join(",") + "\n";

//   // Add data rows
//   data.forEach((item: any) => {
//     const row = columns.map((column: string) => {
//       return item[column] !== undefined ? item[column] : "";
//     });
//     csvContent += row.join(",") + "\n";
//   });

//   // Initiate download
//   initiateDownload(csvContent);
// }

// function initiateDownload(csvContent: string) {
//   // Create a Blob containing the CSV data with text/csv MIME type
//   const blob = new Blob([csvContent], { type: "text/csv" });

//   // Create a temporary anchor element to trigger the download
//   const link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "data.csv";
//   link.click();
// }

// // export const reportData = async (
// //   consumptionData: any[],
// //   deviceName: string,
// //   customerName: string,
// //   templateName: string,
// //   reportDuration: string,
// //   fileName: string = "report.pdf", // Default file name
// //   options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
// // ) => {
// //   try {
// //     const { fontSize = 12, fontStyle = "helvetica" } = options;

// //     const pdf = new jsPDF();

// //     const pageWidth = pdf.internal.pageSize.getWidth();
// //     const pageHeight = pdf.internal.pageSize.getHeight();

// //     const responsiveFontSize = fontSize * (pageWidth / 210);

// //     pdf.setFont(fontStyle);
// //     pdf.setFontSize(responsiveFontSize);

// //     const logoWidth = 50;
// //     const logoHeight = 20;
// //     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

// //     pdf.setFontSize(14);
// //     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

// //     pdf
// //       .setFontSize(responsiveFontSize * 1.5)
// //       .setFontSize(16)
// //       .setFont("", "bold");
// //     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
// //     pdf
// //       .setFontSize(responsiveFontSize * 1.5)
// //       .setFontSize(8)
// //       .setFont("", "normal");

// //     pdf.setFontSize(responsiveFontSize);
// //     pdf.text(`Device Name: ${deviceName}`, 15, 50);
// //     pdf.text(`Report Duration: ${reportDuration}`, 15, 55);

// //     const startY = 60; // Adjust the startY position for table

// //     const tableColumns = Object.keys(consumptionData[0]).filter(
// //       (key) => key !== "logId" && key !== "rssi"
// //     );
// //     const lowercaseColumns = tableColumns.map((column) =>
// //       column.toLocaleUpperCase()
// //     );
// //     const tableRows = consumptionData.map((entry) =>
// //       tableColumns.map((column) => entry[column])
// //     );

// //     (pdf as any).autoTable({
// //       head: [lowercaseColumns],
// //       body: tableRows,
// //       startY,
// //       headStyles: { fontSize: 8 },
// //     });

// //     // Add customer name and additional information
// //     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
// //     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

// //     // Add report generated on and by
// //     const generatedOnY = additionalInfoY + 10;
// //     pdf.setFontSize(10);
// //     pdf.text(
// //       `Report Generated On: ${new Date().toLocaleString()}`,
// //       20,
// //       generatedOnY
// //     );
// //     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

// //     pdf.save(fileName);
// //   } catch (error) {
// //     console.error("Error generating PDF:", error);
// //   }
// // };

// export const reportData1 = async (
//   consumptionData: any[],
//   columns: any[],
//   deviceName: string,
//   customerName: string,
//   templateName: string,
//   reportDuration: string,
//   fileName: string = "report.pdf", // Default file name
//   options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
// ) => {
//   try {
//     const { fontSize = 12, fontStyle = "helvetica" } = options;
//     const pdf = new jsPDF();
//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const responsiveFontSize = fontSize * (pageWidth / 210);
//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);
//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);
//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);
//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(16)
//       .setFont("", "bold");
//     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(8)
//       .setFont("", "normal");

//     pdf.setFontSize(responsiveFontSize);
//     pdf.text(`Device Name: ${deviceName}`, 15, 50);
//     pdf.text(`Report Duration: ${reportDuration}`, 15, 55);
//     const startY = 60; // Adjust the startY position for table
//     const tableColumns = columns;
//     const lowercaseColumns = tableColumns.map((column) =>
//       column.toLocaleUpperCase()
//     );
//     const tableRows = consumptionData.map((entry) =>
//       tableColumns.map((column) => entry[column])
//     );

//     (pdf as any).autoTable({
//       head: [lowercaseColumns],
//       body: tableRows,
//       startY,
//       headStyles: { fontSize: 8 },
//     });

//     // Add customer name and additional information
//     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
//     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

//     // Add report generated on and by
//     const generatedOnY = additionalInfoY + 10;
//     pdf.setFontSize(10);
//     pdf.text(
//       `Report Generated On: ${new Date().toLocaleString()}`,
//       20,
//       generatedOnY
//     );
//     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

// // export const reportDataWithGraph = async (
// //   chartRef: React.RefObject<HTMLDivElement>,
// //   consumptionData: any[],
// //   deviceName: string,
// //   customerName: string,
// //   templateName: string,
// //   reportDuration: string,
// //   fileName: string = "report.pdf", // Default file name
// //   options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
// // ) => {
// //   try {
// //     const { fontSize = 12, fontStyle = "helvetica" } = options;

// //     const pdf = new jsPDF();

// //     const pageWidth = pdf.internal.pageSize.getWidth();
// //     // const pageHeight = pdf.internal.pageSize.getHeight();

// //     const responsiveFontSize = fontSize * (pageWidth / 210);

// //     pdf.setFont(fontStyle);
// //     pdf.setFontSize(responsiveFontSize);

// //     const logoWidth = 50;
// //     const logoHeight = 20;
// //     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

// //     pdf.setFontSize(14);
// //     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

// //     // Set the font size to the responsiveFontSize * 1.5 for the bold text
// //     pdf
// //       .setFontSize(responsiveFontSize * 1.5)
// //       .setFontSize(16)
// //       .setFont("", "bold");
// //     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
// //     pdf
// //       .setFontSize(responsiveFontSize * 1.5)
// //       .setFontSize(8)
// //       .setFont("", "normal");

// //     pdf.setFontSize(responsiveFontSize);
// //     pdf.text(`Device Name: ${deviceName}`, 20, 50);
// //     pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

// //     if (chartRef.current) {
// //       const canvas = await html2canvas(chartRef.current, {
// //         scale: 2, // Increase scale to improve quality
// //       });
// //       const chartImgData = canvas.toDataURL("image/png");
// //       pdf.addImage(chartImgData, "PNG", 15, 70, 180, 100); // Adjust position and size
// //     }

// //     const startY = 180; // Adjust the startY position for table

// //     const tableColumns = Object.keys(consumptionData[0]).filter(
// //       (key) => key !== "logId"
// //     );
// //     const tableRows = consumptionData.map((entry) =>
// //       tableColumns.map((column) => entry[column])
// //     );

// //     const uppercase = tableColumns.map((column) => column.toUpperCase());
// //     (pdf as any).autoTable({
// //       head: [uppercase],
// //       body: tableRows,
// //       startY,
// //     });

// //     // Add customer name and additional information
// //     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
// //     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

// //     // Add report generated on and by
// //     const generatedOnY = additionalInfoY + 10;
// //     pdf.setFontSize(10);
// //     pdf.text(
// //       `Report Generated On: ${new Date().toLocaleString()}`,
// //       20,
// //       generatedOnY
// //     );
// //     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

// //     pdf.save(fileName);
// //   } catch (error) {
// //     console.error("Error generating PDF:", error);
// //   }
// // };

// // export const reportDataWithGraph = async (
// //   chartRef: React.RefObject<HTMLDivElement>,
// //   consumptionData: any[],
// //   deviceName: string,
// //   customerName: string,
// //   templateName: string,
// //   reportDuration: string,
// //   fileName: string = "report.pdf",
// //   options: { fontSize?: number; fontStyle?: string } = {}
// // ) => {
// //   try {
// //     const { fontSize = 12, fontStyle = "helvetica" } = options;

// //     const pdf = new jsPDF({
// //       compress: true // Enable compression
// //     });

// //     const pageWidth = pdf.internal.pageSize.getWidth();
// //     const responsiveFontSize = fontSize * (pageWidth / 210);

// //     pdf.setFont(fontStyle);
// //     pdf.setFontSize(responsiveFontSize);

// //     const logoWidth = 50;
// //     const logoHeight = 20;
// //     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

// //     pdf.setFontSize(14);
// //     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

// //     pdf.setFontSize(responsiveFontSize * 1.5).setFont("", "bold");
// //     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });

// //     pdf.setFontSize(responsiveFontSize).setFont("", "normal");
// //     pdf.text(`Device Name: ${deviceName}`, 20, 50);
// //     pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

// //     if (chartRef.current) {
// //       const canvas = await html2canvas(chartRef.current, {
// //         scale: 1.5, // Reduce scale to 1.5 to decrease image resolution
// //         useCORS: true
// //       });
// //       const chartImgData = canvas.toDataURL("image/jpeg", 0.75); // Convert to JPEG and reduce quality
// //       pdf.addImage(chartImgData, "JPEG", 15, 70, 180, 100); // Adjust position and size
// //     }

// //     const startY = 180;

// //     const tableColumns = Object.keys(consumptionData[0]).filter(
// //       (key) => key !== "logId"
// //     );
// //     const tableRows = consumptionData.map((entry) =>
// //       tableColumns.map((column) => entry[column])
// //     );

// //     const uppercase = tableColumns.map((column) => column.toUpperCase());
// //     (pdf as any).autoTable({
// //       head: [uppercase],
// //       body: tableRows,
// //       startY,
// //       theme: 'grid',
// //       styles: { fontSize: responsiveFontSize * 0.75 } // Reduce font size in table
// //     });

// //     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10;
// //     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

// //     const generatedOnY = additionalInfoY + 10;
// //     pdf.setFontSize(10);
// //     pdf.text(
// //       `Report Generated On: ${new Date().toLocaleString()}`,
// //       20,
// //       generatedOnY
// //     );
// //     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

// //     pdf.save(fileName);
// //   } catch (error) {
// //     console.error("Error generating PDF:", error);
// //   }
// // };

// export const reportDataWithGraph = async (
//   chartRef: React.RefObject<HTMLDivElement>,
//   consumptionData: any[],
//   deviceName: string,
//   customerName: string,
//   templateName: string,
//   reportDuration: string,
//   fileName: string = "report.pdf",
//   options: { fontSize?: number; fontStyle?: string } = {}
// ) => {
//   try {
//     const { fontSize = 12, fontStyle = "helvetica" } = options;

//     const pdf = new jsPDF({
//       compress: true, // Enable compression
//     });

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     pdf.setFontSize(responsiveFontSize * 1.5).setFont("", "bold");
//     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });

//     pdf.setFontSize(responsiveFontSize).setFont("", "normal");
//     pdf.text(`Device Name: ${deviceName}`, 20, 50);
//     pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

//     if (chartRef.current) {
//       const canvas = await html2canvas(chartRef.current, {
//         scale: 1.5, // Reduce scale to 1.5 to decrease image resolution
//         useCORS: true,
//       });
//       const chartImgData = canvas.toDataURL("image/jpeg", 0.75); // Convert to JPEG and reduce quality
//       pdf.addImage(chartImgData, "JPEG", 15, 70, 180, 100); // Adjust position and size
//     }

//     const startY = 180;

//     const tableColumns = Object.keys(consumptionData[0]).filter(
//       (key) => key !== "logId"
//     );
//     const tableRows = consumptionData.map((entry) =>
//       tableColumns.map((column) => entry[column])
//     );

//     const uppercase = tableColumns.map((column) => column.toUpperCase());
//     (pdf as any).autoTable({
//       head: [uppercase],
//       body: tableRows,
//       startY,
//     });

//     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10;
//     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

//     const generatedOnY = additionalInfoY + 10;
//     pdf.setFontSize(10);
//     pdf.text(
//       `Report Generated On: ${new Date().toLocaleString()}`,
//       20,
//       generatedOnY
//     );
//     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

// interface DeviceData {
//   deviceName: string;
//   data: any[]; // Define your data structure appropriately
// }

// export const generatePDFReport = async (
//   deviceData: DeviceData[],
//   name: String,
//   fileName = "report.pdf"
// ) => {
//   try {
//     const pdf = new jsPDF();
//     const fontSize = 12,
//       fontStyle = "helvetica";

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const pageHeight = pdf.internal.pageSize.getHeight();

//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     let isFirstDevice = true; // Flag to indicate the first device
//     let startY: number;
//     let additionalInfoY = 50; // Initialize additionalInfoY with a default value

//     deviceData.forEach((d, i) => {
//       const deviceName = d["deviceName"];
//       const data: any = d["data"];

//       if (data.data.length === 0) {
//         return; // If no data, move to the next iteration
//       }

//       if (!isFirstDevice) {
//         pdf.addPage(); // Add a new page for each device's data except the first one
//       }

//       startY = isFirstDevice ? 35 : 20; // Set startY based on whether it's the first device

//       pdf.setFontSize(16);
//       pdf.text(`Device Name : ${deviceName}`, 15, startY + 12);

//       const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
//       startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

//       const tableColumns = Object.keys(data.data[0]).filter(
//         (key) => key !== "logId"
//       );

//       const tableRows = data.data
//         .filter((entry: any) => {
//           // Check if any field in the entry contains "ERROR"
//           for (const column of tableColumns) {
//             if (
//               entry[column] &&
//               typeof entry[column] === "string" &&
//               entry[column].includes("ERROR")
//             ) {
//               return false; // Exclude this entry if "Error" is found in any column
//             }
//           }
//           return true;
//         })
//         .map((entry: any) =>
//           tableColumns.map((column) => {
//             // Check if the column is 'timeStamp'
//             if (column === "timeStamp") {
//               return formatTimestamp(entry[column]);
//             }
//             // Check if the column is 'Unit', if so, append "m³" to the value
//             if (column === "Unit") {
//               entry[column] = "";
//               return entry[column] + " m³";
//             }
//             // Return other columns as they are
//             return entry[column];
//           })
//         );

//       pdf.setFontSize(10);
//       pdf.text(" ", 200, startY + 15);
//       const uppercase = tableColumns.map((key) => key.toUpperCase());

//       // Split the table if the number of columns exceeds 8
//       const numTables = Math.ceil(tableColumns.length / 8);
//       let tableStartY = startY;
//       for (let j = 0; j < numTables; j++) {
//         const startIdx = j * 8;
//         const endIdx = Math.min(startIdx + 8, tableColumns.length);
//         const slicedColumns = tableColumns.slice(startIdx, endIdx);
//         const slicedRows = tableRows.map((row: any) =>
//           row.slice(startIdx, endIdx)
//         );

//         (pdf as any).autoTable({
//           head: [uppercase.slice(startIdx, endIdx)],
//           body: slicedRows,
//           startY: tableStartY,
//         });

//         tableStartY += (pdf as any).previousAutoTable.finalY + 10;
//       }

//       isFirstDevice = false; // Update the flag after processing the first device

//       additionalInfoY = tableStartY + 10;
//     });
//     pdf.setFontSize(10);
//     const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
//     const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
//     pdf.text(generatedOnText, 20, generatedOnTextY);
//     pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

// export function formatIndianTime(dateValue: any) {
//   const date = new Date(dateValue);

//   const formattedDate = date.toLocaleString("en-IN", {
//     day: "2-digit",
//     month: "2-digit",
//     year: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     hour12: false, // Ensure 24-hour format
//   });

//   return formattedDate;
// }

// export function formatDate(date: any) {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// }
import jsPDF from "jspdf";
import { http } from "../helper/http";
import { ApiResponseGetAllCustomer } from "../types/CustomerTypes";
import { ApiResponseGetAllTags } from "../types/TagTypes";
import { ApiResponseGetAllTemplate } from "../types/TemplateType";
import html2canvas from "html2canvas";
import frinsologo from "../images/Atharv.jpeg";
import "jspdf-autotable"; // Import the autoTable plugin

const baseUrl = process.env.REACT_APP_BASE_URL;
export const fetchAllCustomer = async () => {
  try {
    return (await http(
      `${baseUrl}/customer?allcustomer=all`
    )) as ApiResponseGetAllCustomer;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const fetchAllTemplate = async () => {
  try {
    return (await http(
      `${baseUrl}/templates?alltemplates=all`
    )) as ApiResponseGetAllTemplate;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const fetchAllTags = async () => {
  try {
    return (await http(baseUrl + "/tags?alltags=all")) as ApiResponseGetAllTags;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// START FUNCTION TO FIND THE CONSUMTION DATA BASED ON POSITIVE CUMULATIVE
interface DataItem {
  timestamp: string;
  positivecumulative: string;
}

interface HourlyData {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstPositivecumulative: string;
  lastPositivecumulative: string;
  hour: string;
}
// STEP 1
// the below function will return array of object of last 24 hour from current
// the function will return 1sttime stamp data and last time stamp data and
// [
//   {
//     firstTimeStamp: '2024-03-10 01:02:30',
//     lastTimeStamp: '2024-03-10 01:59:30',
//     firstPositivecumulative: '383.152',
//     lastPositivecumulative: '3563.152',
//     hour: '2024-03-10T01'
//   },
//   {
//     firstTimeStamp: '2024-03-10 07:10:08',
//     lastTimeStamp: '2024-03-10 07:58:08',
//     firstPositivecumulative: '3833.152',
//     lastPositivecumulative: '353.152',
//     hour: '2024-03-10T07'
//   },]
export function filterDataFromEachHourLast24Hours(
  data: DataItem[]
): HourlyData[] {
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Calculate the timestamp for 24 hours ago from the current time
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

  // Filter the array to include only items from the last 24 hours from the current hour
  const last24HoursData = data.filter(
    (item) => new Date(item.timestamp) >= twentyFourHoursAgo
  );
 
  // Initialize an array to store the results
  const result: HourlyData[] = [];

  // Process the data
  last24HoursData.forEach((item) => {
    const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

    const index = result.findIndex(
      (hourlyData) => hourlyData.hour === hourTimestamp
    );

    if (index === -1) {
      // If the hour doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstPositivecumulative: item.positivecumulative,
        lastPositivecumulative: item.positivecumulative,
        hour: hourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and lastPositivecumulative for the current hour
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastPositivecumulative = item.positivecumulative;
    }
  });

  return result;
}

interface HourlyData {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstPositivecumulative: string;
  lastPositivecumulative: string;
  hour: string;
}
// STEP 2
// this function will destructure the array ofHourlyData data based on need it
export function processHourlyData(inputData: HourlyData[]): HourlyData[] {
  const processedData: HourlyData[] = [];

  // Process the first object separately
  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstPositivecumulative: inputData[0].firstPositivecumulative,
    lastPositivecumulative: inputData[0].lastPositivecumulative,
    hour: inputData[0].hour,
  });

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
      firstPositivecumulative: previousHour.lastPositivecumulative,
      lastPositivecumulative: currentHour.lastPositivecumulative,
      hour: currentHour.hour,
    });
  }

  return processedData;
}

//  STEP 3

interface HourlyData {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstPositivecumulative: string;
  lastPositivecumulative: string;
  hour: string;
}

interface ConsumptionData {
  hour: string;
  consumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}
// this will return ths consumtion data
export function processConsumptionData(
  inputData: HourlyData[]
): ConsumptionData[] {
  const consumptionData: ConsumptionData[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    const consumption: ConsumptionData = {
      hour: currentHour.hour,
      consumption: Math.abs(
        parseFloat(currentHour.firstPositivecumulative) -
          parseFloat(currentHour.lastPositivecumulative)
      ),
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}

// END FUNCTION TO FIND THE CONSUMTION DATA BASED ON POSITIVE CUMULATIVE

export function fillMissingHours(data: any) {
  const result: {
    hour: string;
    consumption: number;
    firstTimeStamp: null;
    lastTimeStamp: null;
  }[] = [];
  const currentDate = new Date(); // Get current date and time

  // Adjust current date and time to IST
  const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

  // Generate the last 24 hours in IST format 'YYYY-MM-DD HH'
  const last24Hours = [];
  for (let i = 0; i < 24; i++) {
    const hourAgo = new Date(currentISTDate.getTime() - i * 60 * 60 * 1000);
    const formattedHour = hourAgo.toISOString().slice(0, 13).replace("T", " ");
    last24Hours.push(formattedHour);
  }

  // Iterate over the generated hours and check if each hour is present in the data
  last24Hours.reverse().forEach((hour) => {
    const foundData = data.find((item: { hour: string }) => item.hour === hour);

    // If data for the hour is found, add it to the result, otherwise add default values
    if (foundData) {
      result.push(foundData);
    } else {
      result.push({
        hour: hour,
        consumption: 0,
        firstTimeStamp: null,
        lastTimeStamp: null,
      });
    }
  });

  return result;
}

export const generateAndDownloadPDF = async (
  consumtionData: any[],
  chartRef: React.RefObject<HTMLDivElement>,
  deviceName: string,
  customerName: string
) => {
  try {
    const pdf = new jsPDF(); // Create a new PDF instance
    pdf.setFont("helvetica"); // Set default font
    pdf.setFontSize(12); // Set default font size

    // Add Frinso heading
    pdf.setFontSize(24); // Set font size to 18
    pdf.text(deviceName, pdf.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    }); // Center the text
    // Add logo image
    // const logoWidth = 30; // Adjust the width of the logo image
    // const logoHeight = 20; // Adjust the height of the logo image
    // const logoX = (pdf.internal.pageSize.getWidth() - logoWidth) / 2; // Calculate X position to center horizontally
    // pdf.addImage(img, "JPEG", logoX, 15, logoWidth, logoHeight); // Adjust position and size

    // Add chart image if available
    if (chartRef.current) {
      const canvas = await html2canvas(chartRef.current, {
        scale: 2, // Increase scale to improve quality
      });
      const chartImgData = canvas.toDataURL("image/png");
      pdf.addImage(chartImgData, "PNG", 15, 50, 180, 100); // Adjust position and size
    }

    // Add tabular data using autoTable plugin
    const tableColumns = [
      "Hour",
      "Consumption",
      "First Time Stamp",
      "Last Time Stamp",
    ];
    const tableRows = consumtionData.map((entry) => [
      entry.hour,
      entry.consumption ? entry.consumption.toFixed(2) : "0",
      entry.firstTimeStamp,
      entry.lastTimeStamp,
    ]);
    (pdf as any).autoTable({
      // Cast pdf as any to avoid TypeScript error
      head: [tableColumns],
      body: tableRows,
      startY: 165, // Adjust the startY position to leave space below the chart
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const fontSize = 18; // Set font size to 18
    const fontStyle = "helvetica"; // Set font style

    // Calculate text width based on font size and content
    const textWidth =
      (pdf.getStringUnitWidth(customerName) * fontSize) /
      pdf.internal.scaleFactor;

    // Set the X position for the text, move to the right by 20 units from center
    const xPos = pdfWidth / 2 + textWidth / 2 + 50; // Adjusted to move more to the right

    // Set the Y position for the text (assuming 10 padding from bottom)
    const yPos = pdfHeight - 10;

    // Set font size and font style
    pdf.setFontSize(fontSize);
    pdf.setFont(fontStyle);

    // Add the text
    pdf.text(customerName, xPos, yPos, { align: "right" });

    // Save or download the PDF
    pdf.save("Atharv.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

//START WATER QUALITY
interface TestData {
  dotemp: string;
  dovalue: string;
  phtemp: string;
  phvalue: string;
  tdstemp: string;
  tdsvalue: string;
  timestamp: string;
}

export function filterDataLast24Hours(testData: TestData[]): TestData[] {
  // Step 1: Get the current date and time in UTC
  let currentUTCTime = new Date();

  // Step 2: Convert UTC time to Indian Standard Time (IST)
  let ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  let currentISTTime = new Date(currentUTCTime.getTime() + ISTOffset);

  // Step 3: Calculate the date and time 24 hours ago in IST
  let twentyFourHoursAgoIST = new Date(
    currentISTTime.getTime() - 24 * 60 * 60 * 1000
  );

  // Step 4: Filter the data based on timestamps falling within the last 24 hours in IST
  let filteredDataIST = testData.filter((item) => {
    let itemTimestamp = new Date(item.timestamp);
    return (
      itemTimestamp >= twentyFourHoursAgoIST && itemTimestamp <= currentISTTime
    );
  });

  return filteredDataIST;
}

interface TestData {
  dotemp: string;
  dovalue: string;
  phtemp: string;
  phvalue: string;
  tdstemp: string;
  tdsvalue: string;
  timestamp: string;
}

export function groupDataByHour(data: TestData[]): {
  [hour: number]: TestData;
} {
  const groupedData: { [hour: number]: TestData } = {};

  data.forEach((item) => {
    const hour = new Date(item.timestamp).getHours();
    if (
      !groupedData[hour] ||
      new Date(groupedData[hour].timestamp) < new Date(item.timestamp)
    ) {
      groupedData[hour] = item;
    }
  });

  return groupedData;
}

export function getLast24HoursIST() {
  // Get current date and time in Indian Standard Time (IST)
  const currentDateIST = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  // Initialize an array to store the result
  const hoursArrayIST = [];

  // Loop through the last 24 hours
  for (let i = 0; i < 24; i++) {
    // Create a new date object for each hour
    const hourDateIST = new Date(currentDateIST.getTime() - i * 60 * 60 * 1000);

    // Format the date in the desired format
    const formattedHourIST = `${hourDateIST.getFullYear()}-${(
      hourDateIST.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${hourDateIST
      .getDate()
      .toString()
      .padStart(2, "0")} ${hourDateIST
      .getHours()
      .toString()
      .padStart(2, "0")}:00:00`;

    // Add the formatted hour to the array
    hoursArrayIST.unshift(formattedHourIST);
  }

  // Return the array
  return hoursArrayIST;
}

// END WATER QUALITY

export const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

interface DataItem1 {
  timestamp: string;
  totalizer: string;
}

interface HourlyData1 {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstTotalizer: string;
  lastTotalizer: string;
  hour: string;
}
export function filterDataFromEachHourLast24HoursTotalizer(
  data: DataItem1[]
): HourlyData1[] {
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Calculate the timestamp for 24 hours ago from the current time
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  // Filter the array to include only items from the last 24 hours from the current hour
  const last24HoursData = data.filter(
    (item) => new Date(item.timestamp) >= twentyFourHoursAgo
  );
  // Initialize an array to store the results
  const result: HourlyData1[] = [];

  // Process the data
  last24HoursData.forEach((item) => {
    const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

    const index = result.findIndex(
      (hourlyData) => hourlyData.hour === hourTimestamp
    );

    if (index === -1) {
      // If the hour doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstTotalizer: item.totalizer,
        lastTotalizer: item.totalizer,
        hour: hourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and lastPositivecumulative for the current hour
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastTotalizer = item.totalizer;
    }
  });

  return result;
}

export function processHourlyDataTotalizer(
  inputData: HourlyData1[]
): HourlyData1[] {
  const processedData: HourlyData1[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstTotalizer: inputData[0].firstTotalizer,
    lastTotalizer: inputData[0].lastTotalizer,
    hour: inputData[0].hour,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
      firstTotalizer: previousHour.lastTotalizer,
      lastTotalizer: currentHour.lastTotalizer,
      hour: currentHour.hour,
    });
  }

  return processedData;
}

interface ConsumptionData1 {
  hour: string;
  consumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}
// this will return ths consumtion data
export function processConsumptionDataTotalizer(
  inputData: HourlyData1[]
): ConsumptionData1[] {
  const consumptionData: ConsumptionData1[] = [];

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let c = +currentHour.firstTotalizer - +currentHour.lastTotalizer;

    if (c < 0) {
      c = c * -1;
    }

    const consumption: ConsumptionData = {
      hour: currentHour.hour,
      consumption: c,
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}

export function getIndianCurrentTime() {
  let currentUTCTime = new Date();

  // Convert UTC time to Indian Standard Time (IST)
  let ISTOffset = 330; // Offset in minutes for IST (5 hours 30 minutes)
  let indianTime = new Date(currentUTCTime.getTime() + ISTOffset * 60000);

  // Format the Indian Standard Time (IST)
  let formattedISTTime = indianTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return formattedISTTime;
}

// export function generateCSV(columns: string[], data: any[]) {
//   // Prepare CSV content
//   let csvContent = "";

//   // Convert column names to lowercase for comparison
//   const lowercaseColumns = columns
//     .filter((column) => !column.includes("#")) // Filter out columns containing #
//     .map((column) => column.toLowerCase()); // Convert remaining columns to lowercase

//   // Add header row with column names
//   csvContent += lowercaseColumns.join(",") + "\n";

//   // Add data rows
//   data.forEach((item: any) => {
//     const row = lowercaseColumns.map((column: string) => {
//       const matchingKey = Object.keys(item).find(
//         (key) => key.toLowerCase() === column
//       );
//       return matchingKey !== undefined ? item[matchingKey] : "";
//     });
//     csvContent += row.join(",") + "\n";
//   });

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvContent], { type: "text/csv" });

//   // Create a temporary anchor element to trigger the download
//   const link = document.createElement("a");
//   link.href = URL.createObjectURL(blob);
//   link.download = "data.csv";
//   link.click();
// }

export function generateCSV(columns: string[], data: any[]) {
  // Prepare CSV content
  console.log('columns', columns);
  console.log('data', data);
  let csvContent = "";

  // Add header row with column names
  csvContent += columns.join(",") + "\n";

  // Add data rows
  data.forEach((item: any) => {
    const row = columns.map((column: string) => {
      // Normalize the column name for matching with object keys
      const normalizedColumn = column.toLowerCase().replace(/ /g, "").replace("#", "");
      const matchingKey = Object.keys(item).find(
        (key) => key.toLowerCase().replace(/ /g, "") === normalizedColumn
      );
      return matchingKey !== undefined ? item[matchingKey] : "";
    });
    csvContent += row.join(",") + "\n";
  });

  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";
  link.click();
}

export function generateCSV1(data: any[]) {
  if (data.length === 0) {
    console.error("Data array is empty");
    return;
  }

  // Extract column names from the keys of the first item in the data array
  const columns = Object.keys(data[0]).filter((key) => key !== "logId");

  // Prepare CSV content
  let csvContent = "";

  // Add header row with column names
  csvContent += columns.join(",") + "\n";

  // Add data rows
  data.forEach((item: any) => {
    const row = columns.map((column: string) => {
      return item[column] !== undefined ? item[column] : "";
    });
    csvContent += row.join(",") + "\n";
  });

  // Initiate download
  initiateDownload(csvContent);
}

export function generateCSVForLineChart(data: any[]) {
  console.log('data',data)
  if (data.length === 0) {
    console.error("Data array is empty");
    return;
  }

  // Extract column names from the keys of the first item in the data array
  const columns = Object.keys(data[0]).filter((key) => key !== "logId");

  // Prepare CSV content
  let csvContent = "";

  // Add header row with column names
  csvContent += columns.join(",") + "\n";

  // Add data rows
  data.forEach((item: any) => {
    const row = columns.map((column: string) => {
      return item[column] !== undefined ? item[column] : "";
    });
    csvContent += row.join(",") + "\n";
  });

  // Initiate download
  initiateDownload(csvContent);
}

function initiateDownload(csvContent: string) {
  // Create a Blob containing the CSV data with text/csv MIME type
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Report_Hour_Flow_Summary.csv";
  link.click();
}

// export const reportData = async (
//   consumptionData: any[],
//   deviceName: string,
//   customerName: string,
//   templateName: string,
//   reportDuration: string,
//   fileName: string = "report.pdf", // Default file name
//   options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
// ) => {
//   try {
//     const { fontSize = 12, fontStyle = "helvetica" } = options;

//     const pdf = new jsPDF();

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const pageHeight = pdf.internal.pageSize.getHeight();

//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(16)
//       .setFont("", "bold");
//     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(8)
//       .setFont("", "normal");

//     pdf.setFontSize(responsiveFontSize);
//     pdf.text(`Device Name: ${deviceName}`, 15, 50);
//     pdf.text(`Report Duration: ${reportDuration}`, 15, 55);

//     const startY = 60; // Adjust the startY position for table

//     const tableColumns = Object.keys(consumptionData[0]).filter(
//       (key) => key !== "logId" && key !== "rssi"
//     );
//     const lowercaseColumns = tableColumns.map((column) =>
//       column.toLocaleUpperCase()
//     );
//     const tableRows = consumptionData.map((entry) =>
//       tableColumns.map((column) => entry[column])
//     );

//     (pdf as any).autoTable({
//       head: [lowercaseColumns],
//       body: tableRows,
//       startY,
//       headStyles: { fontSize: 8 },
//     });

//     // Add customer name and additional information
//     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
//     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

//     // Add report generated on and by
//     const generatedOnY = additionalInfoY + 10;
//     pdf.setFontSize(10);
//     pdf.text(
//       `Report Generated On: ${new Date().toLocaleString()}`,
//       20,
//       generatedOnY
//     );
//     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

export const reportData1 = async (
  consumptionData: any[],
  columns: any[],
  deviceName: string,
  customerName: string,
  templateName: string,
  reportDuration: string,
  fileName: string = "report.pdf", // Default file name
  options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
) => {
  try {
    const { fontSize = 12, fontStyle = "helvetica" } = options;
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const responsiveFontSize = fontSize * (pageWidth / 210);
    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);
    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);
    pdf.setFontSize(14);
    pdf.text("ATHARV TECHNOLOGIES Pvt. Ltd.", 120, 20);
    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(16)
      .setFont("", "bold");
    pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
    pdf
      .setFontSize(responsiveFontSize * 1.5)
      .setFontSize(8)
      .setFont("", "normal");

    pdf.setFontSize(responsiveFontSize);
    pdf.text(`Device Name: ${deviceName}`, 15, 50);
    pdf.text(`Report Duration: ${reportDuration}`, 15, 55);
    const startY = 60; // Adjust the startY position for table
    const tableColumns = columns;
    const lowercaseColumns = tableColumns.map((column) =>
      column.toLocaleUpperCase()
    );
    
    // Modified tableRows mapping
    const tableRows = consumptionData.map((entry) =>
      console.log('consumptionData',consumptionData)
      // tableColumns.map((column) => {
      //   if (column.toUpperCase() === 'CONSUMPTION') {
      //     // Format CONSUMPTION to match the image
      //     const value = parseFloat(entry[column]);
      //     if (value === 0) return '0';
      //     if (value < 1) {
      //       return value.toFixed(18).replace(/\.?0+$/, '');
      //     }
      //     return value.toString();
      //   }
      //   return entry[column];
      // })
    );

    (pdf as any).autoTable({
      head: [lowercaseColumns],
      body: tableRows,
      startY,
      headStyles: { fontSize: 8 },
    });

    // Add customer name and additional information
    const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
    pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

    // Add report generated on and by
    const generatedOnY = additionalInfoY + 10;
    pdf.setFontSize(10);
    pdf.text(
      `Report Generated On: ${new Date().toLocaleString()}`,
      20,
      generatedOnY
    );
    pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

    pdf.save(fileName);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

// export const reportDataWithGraph = async (
//   chartRef: React.RefObject<HTMLDivElement>,
//   consumptionData: any[],
//   deviceName: string,
//   customerName: string,
//   templateName: string,
//   reportDuration: string,
//   fileName: string = "report.pdf", // Default file name
//   options: { fontSize?: number; fontStyle?: string } = {} // Additional options for customization
// ) => {
//   try {
//     const { fontSize = 12, fontStyle = "helvetica" } = options;

//     const pdf = new jsPDF();

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     // const pageHeight = pdf.internal.pageSize.getHeight();

//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     // Set the font size to the responsiveFontSize * 1.5 for the bold text
//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(16)
//       .setFont("", "bold");
//     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });
//     pdf
//       .setFontSize(responsiveFontSize * 1.5)
//       .setFontSize(8)
//       .setFont("", "normal");

//     pdf.setFontSize(responsiveFontSize);
//     pdf.text(`Device Name: ${deviceName}`, 20, 50);
//     pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

//     if (chartRef.current) {
//       const canvas = await html2canvas(chartRef.current, {
//         scale: 2, // Increase scale to improve quality
//       });
//       const chartImgData = canvas.toDataURL("image/png");
//       pdf.addImage(chartImgData, "PNG", 15, 70, 180, 100); // Adjust position and size
//     }

//     const startY = 180; // Adjust the startY position for table

//     const tableColumns = Object.keys(consumptionData[0]).filter(
//       (key) => key !== "logId"
//     );
//     const tableRows = consumptionData.map((entry) =>
//       tableColumns.map((column) => entry[column])
//     );

//     const uppercase = tableColumns.map((column) => column.toUpperCase());
//     (pdf as any).autoTable({
//       head: [uppercase],
//       body: tableRows,
//       startY,
//     });

//     // Add customer name and additional information
//     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10; // Get the Y position after table
//     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

//     // Add report generated on and by
//     const generatedOnY = additionalInfoY + 10;
//     pdf.setFontSize(10);
//     pdf.text(
//       `Report Generated On: ${new Date().toLocaleString()}`,
//       20,
//       generatedOnY
//     );
//     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

// export const reportDataWithGraph = async (
//   chartRef: React.RefObject<HTMLDivElement>,
//   consumptionData: any[],
//   deviceName: string,
//   customerName: string,
//   templateName: string,
//   reportDuration: string,
//   fileName: string = "report.pdf",
//   options: { fontSize?: number; fontStyle?: string } = {}
// ) => {
//   try {
//     const { fontSize = 12, fontStyle = "helvetica" } = options;

//     const pdf = new jsPDF({
//       compress: true // Enable compression
//     });

//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const responsiveFontSize = fontSize * (pageWidth / 210);

//     pdf.setFont(fontStyle);
//     pdf.setFontSize(responsiveFontSize);

//     const logoWidth = 50;
//     const logoHeight = 20;
//     pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

//     pdf.setFontSize(14);
//     pdf.text("FRINSO TECHNOLOGIES PVT. LTD.", 120, 20);

//     pdf.setFontSize(responsiveFontSize * 1.5).setFont("", "bold");
//     pdf.text(templateName, pageWidth / 2, 40, { align: "center" });

//     pdf.setFontSize(responsiveFontSize).setFont("", "normal");
//     pdf.text(`Device Name: ${deviceName}`, 20, 50);
//     pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

//     if (chartRef.current) {
//       const canvas = await html2canvas(chartRef.current, {
//         scale: 1.5, // Reduce scale to 1.5 to decrease image resolution
//         useCORS: true
//       });
//       const chartImgData = canvas.toDataURL("image/jpeg", 0.75); // Convert to JPEG and reduce quality
//       pdf.addImage(chartImgData, "JPEG", 15, 70, 180, 100); // Adjust position and size
//     }

//     const startY = 180;

//     const tableColumns = Object.keys(consumptionData[0]).filter(
//       (key) => key !== "logId"
//     );
//     const tableRows = consumptionData.map((entry) =>
//       tableColumns.map((column) => entry[column])
//     );

//     const uppercase = tableColumns.map((column) => column.toUpperCase());
//     (pdf as any).autoTable({
//       head: [uppercase],
//       body: tableRows,
//       startY,
//       theme: 'grid',
//       styles: { fontSize: responsiveFontSize * 0.75 } // Reduce font size in table
//     });

//     const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10;
//     pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

//     const generatedOnY = additionalInfoY + 10;
//     pdf.setFontSize(10);
//     pdf.text(
//       `Report Generated On: ${new Date().toLocaleString()}`,
//       20,
//       generatedOnY
//     );
//     pdf.text(`Report Generated by: ${customerName}`, 20, generatedOnY + 5);

//     pdf.save(fileName);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   }
// };

export const reportDataWithGraph = async (
  chartRef: React.RefObject<HTMLDivElement>,
  consumptionData: any[],
  deviceName: string,
  customerName: string,
  templateName: string,
  reportDuration: string,
  fileName: string = "Report_Hour_Consumption_Summary.pdf",
  options: { fontSize?: number; fontStyle?: string } = {}
) => {
  try {
    const { fontSize = 12, fontStyle = "helvetica" } = options;

    const pdf = new jsPDF({
      compress: true, // Enable compression
    });
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const responsiveFontSize = fontSize * (pageWidth / 210);

    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);

    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

    pdf.setFontSize(14);
    pdf.text("ATHARV TECHNOLOGIES Pvt. Ltd.", 120, 20);

    pdf.setFontSize(responsiveFontSize * 1.5).setFont("", "bold");
    pdf.text(templateName, pageWidth / 2, 40, { align: "center" });

    pdf.setFontSize(responsiveFontSize).setFont("", "normal");
    pdf.text(`Device Name: ${deviceName}`, 20, 50);
    pdf.text(`Report Duration: ${reportDuration}`, 20, 55);

    if (chartRef.current) {
      const canvas = await html2canvas(chartRef.current, {
        scale: 1.5, // Reduce scale to 1.5 to decrease image resolution
        useCORS: true,
      });
      const chartImgData = canvas.toDataURL("image/jpeg", 0.75); // Convert to JPEG and reduce quality
      pdf.addImage(chartImgData, "JPEG", 15, 70, 180, 100); // Adjust position and size
    }

    const startY = 180;

    const tableColumns = Object.keys(consumptionData[0]).filter(
      (key) => key !== "logId"
    );
    const tableRows = consumptionData.map((entry) =>
      tableColumns.map((column) => {
        if (column === "Consumption") {
          // Format Consumption to 3 decimal places
          return Number(entry[column]).toFixed(3);
        }
        return entry[column];
      })
    );
    console.log('tableRows',tableRows)

    const uppercase = tableColumns.map((column) => column.toUpperCase());
    (pdf as any).autoTable({
      head: [uppercase],
      body: tableRows,
      startY,
    });

    const additionalInfoY = (pdf as any).autoTable.previous.finalY + 10;
    pdf.text("", pageWidth - 20, additionalInfoY, { align: "right" });

    const generatedOnY = additionalInfoY + 10;
    pdf.setFontSize(10);
    pdf.text(`Report Generated On:${new Date().toLocaleString()}`, 10, pageHeight - 10);
    
    pdf.text(`Report Generated by: ${customerName}`, 10, pageHeight - 5);

    pdf.save(fileName);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
interface DeviceData {
  deviceName: string;
  data: any[]; // Define your data structure appropriately
}

export const generatePDFReport = async (
  deviceData: DeviceData[],
  name: String,
  fileName = "report.pdf"
) => {
  try {
    const pdf = new jsPDF();
    const fontSize = 12,
      fontStyle = "helvetica";

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const responsiveFontSize = fontSize * (pageWidth / 210);

    pdf.setFont(fontStyle);
    pdf.setFontSize(responsiveFontSize);

    const logoWidth = 50;
    const logoHeight = 20;
    pdf.addImage(frinsologo, "JPEG", 15, 10, logoWidth, logoHeight);

    pdf.setFontSize(14);
    pdf.text("ATHARV TECHNOLOGIES Pvt. Ltd.", 120, 20);

    let isFirstDevice = true; // Flag to indicate the first device
    let startY: number;
    let additionalInfoY = 50; // Initialize additionalInfoY with a default value

    deviceData.forEach((d, i) => {
      const deviceName = d["deviceName"];
      const data: any = d["data"];

      if (data.data.length === 0) {
        return; // If no data, move to the next iteration
      }

      if (!isFirstDevice) {
        pdf.addPage(); // Add a new page for each device's data except the first one
      }

      startY = isFirstDevice ? 35 : 20; // Set startY based on whether it's the first device

      pdf.setFontSize(16);
      pdf.text(`Device Name : ${deviceName}`, 15, startY + 12);

      const deviceNameTextHeight = 2 * (pageWidth / 120); // Assuming fontSize is 16
      startY += deviceNameTextHeight + 10; // Adjust the value according to your preference

      const tableColumns = Object.keys(data.data[0]).filter(
        (key) => key !== "logId"
      );

      const tableRows = data.data
        .filter((entry: any) => {
          // Check if any field in the entry contains "ERROR"
          for (const column of tableColumns) {
            if (
              entry[column] &&
              typeof entry[column] === "string" &&
              entry[column].includes("ERROR")
            ) {
              return false; // Exclude this entry if "Error" is found in any column
            }
          }
          return true;
        })
        .map((entry: any) =>
          tableColumns.map((column) => {
            // Check if the column is 'timeStamp'
            if (column === "timeStamp") {
              return formatTimestamp(entry[column]);
            }
            // Check if the column is 'Unit', if so, append "m³" to the value
            if (column === "Unit") {
              entry[column] = "";
              return entry[column] + " m³";
            }
            // Return other columns as they are
            return entry[column];
          })
        );

      pdf.setFontSize(10);
      pdf.text(" ", 200, startY + 15);
      const uppercase = tableColumns.map((key) => key.toUpperCase());

      // Split the table if the number of columns exceeds 8
      const numTables = Math.ceil(tableColumns.length / 8);
      let tableStartY = startY;
      for (let j = 0; j < numTables; j++) {
        const startIdx = j * 8;
        const endIdx = Math.min(startIdx + 8, tableColumns.length);
        const slicedColumns = tableColumns.slice(startIdx, endIdx);
        const slicedRows = tableRows.map((row: any) =>
          row.slice(startIdx, endIdx)
        );

        (pdf as any).autoTable({
          head: [uppercase.slice(startIdx, endIdx)],
          body: slicedRows,
          startY: tableStartY,
        });

        tableStartY += (pdf as any).previousAutoTable.finalY + 10;
      }

      isFirstDevice = false; // Update the flag after processing the first device

      additionalInfoY = tableStartY + 10;
    });
    pdf.setFontSize(10);
    const generatedOnText = `Report Generated On: ${new Date().toLocaleString()}`;
    const generatedOnTextY = pageHeight - 10; // Adjust 10 as needed
    pdf.text(generatedOnText, 20, generatedOnTextY);
    pdf.text("Report Generated By : " + name, 20, generatedOnTextY + 3);

    pdf.save(fileName);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

export function formatIndianTime(dateValue: any) {
  const date = new Date(dateValue);

  const formattedDate = date.toLocaleString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Ensure 24-hour format
  });

  return formattedDate;
}

export function formatDate(date: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getValueFromObject(data: any , id: any) {
  const newData = data.filter((d: any)=> d.id == id);
  return newData[0].name;
}
