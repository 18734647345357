import React, { useCallback, useEffect, useRef, useState } from "react";

// import "../../App.css";
import {
  formatDate,
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";
import { IconButton } from "@mui/material";

import LineChart from "../../../components/apex-chart/LineChart";
import CardThree from "../../../components/dashBoardComponents/CardThree";
import demandIcon from "../../../images/demand (1) 1.png";
import LongMenu from "../../../utils/longMenu/LongMenu";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import { http } from "../../../helper/http";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import {
  fillMissingDays,
  fillMissingHours,
  fillMissingMonths,
  filterDataFromEachDayEnergy,
  filterDataFromEachHalfHourEnergy,
  filterDataFromEachHourEnergy,
  filterDataFromEachMonthEnergy,
  formatNumber,
  processConsumptionDataEnergy,
  processConsumptionHalfHourDataEnergy,
  processHalfHourlyDataEnergy,
  processHourlyDataEnergy,
  processHourlyDataEnergyDaily,
  processHourlyDataEnergyMonthly,
  processMonthlyConsumptionEnergy,
  processYearlyConsumptionEnergy,
} from "./common-functions";
import BarChart from "../../../components/apex-chart/CommonBarChart";
import { Padding } from "@mui/icons-material";


const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardEnergy1 = ({ devices, selectedDevice }: any) => {
  //material tabs
  const [value, setValue] = React.useState(0);
  const [valueAnalysis, setvalueAnalysis] = React.useState(0);
  //instantenious
  const [valueInst, setInst] = React.useState(0);
  const [valueInstPower, setvalueInstPower] = React.useState(0);
  const [valueInstTDH, setvalueInstTDH] = React.useState(0);
  const [valueInstVolt, setValueInstVolt] = React.useState(0);

  const [apiData, setApiData] = useState<any>([]); //setTemperature
  const barchartref1 = useRef<HTMLDivElement>(null);
  const barchartref2 = useRef<HTMLDivElement>(null);
  const barchartref3 = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef2 = useRef<HTMLDivElement>(null);
  const chartRef3 = useRef<HTMLDivElement>(null);
  const chartRef4 = useRef<HTMLDivElement>(null);
  const chartRef5 = useRef<HTMLDivElement>(null);
  const chartRef6 = useRef<HTMLDivElement>(null);
  const chartRef7 = useRef<HTMLDivElement>(null);
  const chartRef8 = useRef<HTMLDivElement>(null);
  const [dashboardSts, setDashboardSts] = useState<any>([]);

  function formatNumericProperty(value: any) {
    //console.log('value',value)
    if (!isNaN(value) && value !== null && value !== undefined) {
      return parseFloat(value).toFixed(3);
    }
    return value;
  }

  const fetchData = async () => {
    let dof = new Date();

    let response: any = null;
    try {
      const formattedDate = formatDate(dof);
      let queryParam = "";
      if (value === 0) {
        queryParam =
          valueAnalysis === 0
            ? `day=${formattedDate.slice(0, 10)}`
            : valueAnalysis === 1
            ? `month=${formattedDate.slice(5, 7)}`
            : "";
      } else if (value === 1) {
        queryParam = `day=${formattedDate.slice(0, 10)}`;
      }
      response = await http(
        `${baseUrl}/device-data-log?deviceId=${selectedDevice}&${queryParam}`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            avgvtg: formatNumericProperty(d["avg_vtg"]),
            rphvtg: formatNumericProperty(d["r_ph_vtg"]),
            yphvtg: formatNumericProperty(d["y_ph_vtg"]),
            ybpgvtg: formatNumericProperty(d["yb_ph_vtg"]),
            bphvtg: formatNumericProperty(d["b_ph_vtg"]),
            ryphvtg: formatNumericProperty(d["ry_ph_vtg"]),
            rbphvtg: formatNumericProperty(d["rb_ph_vtg"]),
            rphcrt: formatNumericProperty(d["r_ph_crt"]),
            avgcrt: formatNumericProperty(d["avg_crt"]),
            yphcrt: formatNumericProperty(d["y_ph_crt"]),
            bphcrt: formatNumericProperty(d["b_ph_crt"]),
            powerfactor: formatNumericProperty(d["power_factor"]),
            rphpf: formatNumericProperty(d["r_ph_pf"]),
            yphpf: formatNumericProperty(d["y_ph_pf"]),
            bphpf: formatNumericProperty(d["b_ph_pf"]),
            totalactpo: formatNumericProperty(d["total_act_po"]),
            rphactpo: formatNumericProperty(d["r_ph_act_po"]),
            yphactpo: formatNumericProperty(d["y_ph_act_po"]),
            bphactpo: formatNumericProperty(d["b_ph_act_po"]),
            totalreactpo: formatNumericProperty(d["total_react_po"]),
            rphreactpo: formatNumericProperty(d["r_ph_react_po"]),
            yphreactpo: formatNumericProperty(d["y_ph_react_po"]),
            bphreactpo: formatNumericProperty(d["b_ph_react_po"]),
            totalapppo: formatNumericProperty(d["total_app_po"]),
            rphapppo: formatNumericProperty(d["r_ph_app_po"]),
            yphapppo: formatNumericProperty(d["y_ph_app_po"]),
            bphapppo: formatNumericProperty(d["b_ph_app_po"]),
            frequency: formatNumericProperty(d["frequency"]),
            activeenergy: formatNumericProperty(d["active_energy"]),
            reactiveenergy: formatNumericProperty(d["reactive_energy"]),
            apparentenergy: formatNumericProperty(d["apparent_energy"]),
            rvtgphthd: formatNumericProperty(d["r_vtg_ph_thd"]),
            yvtgphthd: formatNumericProperty(d["y_vtg_ph_thd"]),
            bvtgphthd: formatNumericProperty(d["b_vtg_ph_thd"]),
            rcrtphthd: formatNumericProperty(d["r_crt_ph_thd"]),
            ycrtphthd: formatNumericProperty(d["y_crt_ph_thd"]),
            bcrtphthd: formatNumericProperty(d["b_crt_ph_thd"]),
            rssi: formatNumericProperty(d["rssi"]),
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(_data.length ? _data[0] : "");
      const sortedData = _data.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      setApiData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(fetchData, 120000);
    // return () => clearInterval(intervalId);
  }, [selectedDevice, valueAnalysis, value]);

  let energy_data_pdfData: any = [];
  let active_energy_last24hour: any = [];
  let reactive_energy_last24hour: any = [];
  let apparent_energy_last24hour: any = [];
  let total_Voltage_of_current_month: any = [];
  let r_phase_Voltage_of_current_month: any = [];
  let y_phase_Voltage_of_current_month: any = [];
  let b_phase_Voltage_of_current_month: any = [];
  let ry_phase_Voltage_of_current_month: any = [];
  let yb_phase_Voltage_of_current_month: any = [];
  let rb_phase_Voltage_of_current_month: any = [];
  let total_Current_of_current_month: any = [];
  let r_phase_Current_of_current_month: any = [];
  let y_phase_Current_of_current_month: any = [];
  let b_phase_Current_of_current_month: any = [];
  let total_Active_power_of_current_month: any = [];
  let r_phase_Active_power_of_current_month: any = [];
  let y_phase_Active_power_of_current_month: any = [];
  let b_phase_Active_power_of_current_month: any = [];
  let total_Reactive_power_of_current_month: any = [];
  let r_phase_Reactive_power_of_current_month: any = [];
  let y_phase_Reactive_power_of_current_month: any = [];
  let b_phase_Reactive_power_of_current_month: any = [];
  let total_Apparent_power_of_current_month: any = [];
  let r_phase_Apparent_power_of_current_month: any = [];
  let y_phase_Apparent_power_of_current_month: any = [];
  let b_phase_Apparent_power_of_current_month: any = [];
  let total_Power_Factor_of_current_month: any = [];
  let r_phase_Power_Factor_of_current_month: any = [];
  let y_phase_Power_Factor_of_current_month: any = [];
  let b_phase_Power_Factor_of_current_month: any = [];
  let frequency_for_pdf: any = [];
  let frequency_of_current_month: any = [];
  let r_phase_THD_Voltage_of_current_month: any = [];
  let y_phase_THD_Voltage_of_current_month: any = [];
  let b_phase_THD_Voltage_of_current_month: any = [];
  let r_phase_THD_Current_of_current_month: any = [];
  let y_phase_THD_Current_of_current_month: any = [];
  let b_phase_THD_Current_of_current_month: any = [];
  let intLWVoltPdfData: any = [];
  let intLLVoltPdfData: any = [];
  let intCurrPdfData: any = [];
  let getPowerFactorData: any = [];
  let getTDHDataToExport: any = [];

  if (value === 0) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({ timestamp, activeenergy, reactiveenergy, apparentenergy }: any) => {
          energy_data_pdfData.push({
            timestamp,
            "Active Energy (kwh)": activeenergy,
            "Reactive Energy (kVarh)": reactiveenergy,
            "Apparent Energy (kVah)": apparentenergy,
          });

          active_energy_last24hour.push({
            timestamp,
            value: activeenergy === "" ? 0 : activeenergy,
          });
//console.log('active_energy_last24hour',active_energy_last24hour)
          reactive_energy_last24hour.push({
            timestamp,
            value: reactiveenergy === "" ? 0 : reactiveenergy,
          });

          apparent_energy_last24hour.push({
            timestamp,
            value: apparentenergy === "" ? 0 : apparentenergy,
          });
        }
      );
    }
  } else if (value === 1) {
    console.log("api data", apiData);
    
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          avgvtg,
          rphvtg,
          yphvtg,
          bphvtg,
          ryphvtg,
          ybpgvtg,
          rbphvtg,
          avgcrt,
          rphcrt,
          yphcrt,
          bphcrt,
          totalactpo,
          rphactpo,
          yphactpo,
          bphactpo,
          totalreactpo,
          rphreactpo,
          yphreactpo,
          bphreactpo,
          totalapppo,
          rphapppo,
          yphapppo,
          bphapppo,
          powerfactor,
          rphpf,
          yphpf,
          bphpf,
          frequency,
          rvtgphthd,
          yvtgphthd,
          bvtgphthd,
          rcrtphthd,
          ycrtphthd,
          bcrtphthd,
        }: any) => {
          total_Voltage_of_current_month.push({
            timestamp,
            value: avgvtg === "" ? 0 : avgvtg,
          });

          r_phase_Voltage_of_current_month.push({
            timestamp,
            value: rphvtg === "" ? 0 : rphvtg,
          });

          y_phase_Voltage_of_current_month.push({
            timestamp,
            value: yphvtg === "" ? 0 : yphvtg,
          });

          b_phase_Voltage_of_current_month.push({
            timestamp,
            value: bphvtg === "" ? 0 : bphvtg,
          });

          ry_phase_Voltage_of_current_month.push({
            timestamp,
            value: ryphvtg === "" ? 0 : ryphvtg,
          });

          yb_phase_Voltage_of_current_month.push({
            timestamp,
            value: ybpgvtg === "" ? 0 : ybpgvtg,
          });

          rb_phase_Voltage_of_current_month.push({
            timestamp,
            value: rbphvtg === "" ? 0 : rbphvtg,
          });

          total_Current_of_current_month.push({
            timestamp,
            value: avgcrt === "" ? 0 : avgcrt,
          });

          r_phase_Current_of_current_month.push({
            timestamp,
            value: rphcrt === "" ? 0 : rphcrt,
          });

          y_phase_Current_of_current_month.push({
            timestamp,
            value: yphcrt === "" ? 0 : yphcrt,
          });

          b_phase_Current_of_current_month.push({
            timestamp,
            value: bphcrt === "" ? 0 : bphcrt,
          });

          total_Active_power_of_current_month.push({
            timestamp,
            value: totalactpo === "" ? 0 : totalactpo,
          });

          r_phase_Active_power_of_current_month.push({
            timestamp,
            value: rphactpo === "" ? 0 : rphactpo,
          });

          y_phase_Active_power_of_current_month.push({
            timestamp,
            value: yphactpo === "" ? 0 : yphactpo,
          });

          b_phase_Active_power_of_current_month.push({
            timestamp,
            value: bphactpo === "" ? 0 : bphactpo,
          });

          total_Reactive_power_of_current_month.push({
            timestamp,
            value: totalreactpo === "" ? 0 : totalreactpo,
          });

          r_phase_Reactive_power_of_current_month.push({
            timestamp,
            value: rphreactpo === "" ? 0 : rphreactpo,
          });

          y_phase_Reactive_power_of_current_month.push({
            timestamp,
            value: yphreactpo === "" ? 0 : yphreactpo,
          });

          b_phase_Reactive_power_of_current_month.push({
            timestamp,
            value: bphreactpo === "" ? 0 : bphreactpo,
          });

          total_Apparent_power_of_current_month.push({
            timestamp,
            value: totalapppo === "" ? 0 : totalapppo,
          });

          r_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: rphapppo === "" ? 0 : rphapppo,
          });

          y_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: yphapppo === "" ? 0 : yphapppo,
          });

          b_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: bphapppo === "" ? 0 : bphapppo,
          });

          total_Power_Factor_of_current_month.push({
            timestamp,
            value: powerfactor === "" ? 0 : powerfactor,
          });

          r_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: rphpf === "" ? 0 : rphpf,
          });

          y_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: yphpf === "" ? 0 : yphpf,
          });

          b_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: bphpf === "" ? 0 : bphpf,
          });

          frequency_for_pdf.push({
            TimeStamp: timestamp,
            Frequency: frequency,
          });

          frequency_of_current_month.push({
            timestamp,
            value: frequency === "" ? 0 : frequency,
          });

          r_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: rvtgphthd === "" ? 0 : rvtgphthd,
          });

          y_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: yvtgphthd === "" ? 0 : yvtgphthd,
          });

          b_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: bvtgphthd === "" ? 0 : bvtgphthd,
          });

          r_phase_THD_Current_of_current_month.push({
            timestamp,
            value: rcrtphthd === "" ? 0 : rcrtphthd,
          });

          y_phase_THD_Current_of_current_month.push({
            timestamp,
            value: ycrtphthd === "" ? 0 : ycrtphthd,
          });

          b_phase_THD_Current_of_current_month.push({
            timestamp,
            value: bcrtphthd === "" ? 0 : bcrtphthd,
          });

          intLWVoltPdfData.push({
            timestamp,
            "Avarage Volt (V)": avgvtg,
            "R Phase Volt (V)": rphvtg,
            "Y Phase Volt (V)": yphvtg,
            "B Phase Volt (V)": bphvtg,
          });

          intLLVoltPdfData.push({
            timestamp,
            "RY Phase Volt (V)": ryphvtg,
            "YB Phase Volt (V)": ybpgvtg,
            "RB Phase Volt (V)": rbphvtg,
          });

          intCurrPdfData.push({
            timestamp,
            "Total Curr (A)": avgcrt,
            "R Phase Curr (A)": rphcrt,
            "Y Phase Curr (A)": yphcrt,
            "B Phase Curr (A)": bphcrt,
          });

          getPowerFactorData.push({
            timestamp,
            "Average PowerFactor": powerfactor,
            "R Phase PowerFactor": rphpf,
            "Y Phase PowerFactor": yphpf,
            "B Phase PowerFactor": bphpf,
          });

          getTDHDataToExport.push({
            timestamp,
            "R Phase THD (%)": rvtgphthd,
            "Y Phase THD (%)": yvtgphthd,
            "B Phase THD (%)": bvtgphthd,
          });
        }
      );
    }
  }

  const linechartEnergyData: any = [
    {
      name: "Active Energy",
      color: "#FF0000",
      data: active_energy_last24hour,
      postfix: " kWh",
    },
    {
      name: "Reactive Energy",
      color: "#FFFF00",
      data: reactive_energy_last24hour,
      postfix: " kVarh",
    },
    {
      name: "Apparent Energy",
      color: "#0000FF",
      data: apparent_energy_last24hour,
      postfix: " kVah",
    },
  ];
  console.log('linechartEnergyData',linechartEnergyData)
  const lineChartOfLWVoltageVariations: any = [
    {
      name: "Average Voltage",
      color: "#008000",
      data: total_Voltage_of_current_month,
      postfix: " V",
    },
    {
      name: "R Phase Voltage",
      color: "#FF0000",
      data: r_phase_Voltage_of_current_month,
      postfix: " V",
    },
    {
      name: "Y Phase Voltage",
      color: "#FFFF00",
      data: y_phase_Voltage_of_current_month,
      postfix: " V",
    },
    {
      name: "B Phase Voltage",
      color: "#0000FF",
      data: b_phase_Voltage_of_current_month,
      postfix: " V",
    },
  ];

  const lineChartOfLLVoltageVariations: any = [
    {
      name: "RY Phase Voltage",
      color: "#FF0000",
      data: ry_phase_Voltage_of_current_month,
      postfix: " V",
    },
    {
      name: "YB Phase Voltage",
      color: "#FFFF00",
      data: yb_phase_Voltage_of_current_month,
      postfix: " V",
    },
    {
      name: "RB Phase Voltage",
      color: "#0000FF",
      data: rb_phase_Voltage_of_current_month,
      postfix: " V",
    },
  ];
  const lineChartOfCurrentVariations: any = [
    {
      name: "Average Current",
      color: "#008000",
      data: total_Current_of_current_month,
      postfix: " A",
    },
    {
      name: "R Phase Current",
      color: "#FF0000",
      data: r_phase_Current_of_current_month,
      postfix: " A",
    },
    {
      name: "Y Phase Current",
      color: "#FFFF00",
      data: y_phase_Current_of_current_month,
      postfix: " A",
    },
    {
      name: "B Phase Current",
      color: "#0000FF",
      data: b_phase_Current_of_current_month,
      postfix: " A",
    },
  ];
  const lineChartOfActivePowerVariations: any = [
    {
      name: "Total Active Power",
      color: "#008000",
      data: total_Active_power_of_current_month,
      postfix: " kW",
    },
    {
      name: "R Phase Active Power",
      color: "#FF0000",
      data: r_phase_Active_power_of_current_month,
      postfix: " kW",
    },
    {
      name: "Y Phase Active Power",
      color: "#FFFF00",
      data: y_phase_Active_power_of_current_month,
      postfix: " kW",
    },
    {
      name: "B Phase Active Power",
      color: "#0000FF",
      data: b_phase_Active_power_of_current_month,
      postfix: " kW",
    },
  ];
  const lineChartOfReactivePowerVariations: any = [
    {
      name: "Total Reactive Power",
      color: "#008000",
      data: total_Reactive_power_of_current_month,
      postfix: " kVAr",
    },
    {
      name: "R Phase Reactive Power",
      color: "#FF0000",
      data: r_phase_Reactive_power_of_current_month,
      postfix: " kVAr",
    },
    {
      name: "Y Phase Reactive Power",
      color: "#FFFF00",
      data: y_phase_Reactive_power_of_current_month,
      postfix: " kVAr",
    },
    {
      name: "B Phase Reactive Power",
      color: "#0000FF",
      data: b_phase_Reactive_power_of_current_month,
      postfix: " kVAr",
    },
  ];
  const lineChartOfApparentPowerVariations: any = [
    {
      name: "Total Apparent Power",
      color: "#008000",
      data: total_Apparent_power_of_current_month,
      postfix: " kVa",
    },
    {
      name: "R Phase Apparent Power",
      color: "#FF0000",
      data: r_phase_Apparent_power_of_current_month,
      postfix: " kVa",
    },
    {
      name: "Y Phase Apparent Power",
      color: "#FFFF00",
      data: y_phase_Apparent_power_of_current_month,
      postfix: " kVa",
    },
    {
      name: "B Phase Apparent Power",
      color: "#0000FF",
      data: b_phase_Apparent_power_of_current_month,
      postfix: " kVa",
    },
  ];
  const lineChartOfPowerFactorVariations: any = [
    {
      name: "Average PF",
      color: "#008000",
      data: total_Power_Factor_of_current_month,
    },
    {
      name: "R Phase PF",
      color: "#FF0000",
      data: r_phase_Power_Factor_of_current_month,
    },
    {
      name: "Y Phase PF",
      color: "#FFFF00",
      data: y_phase_Power_Factor_of_current_month,
    },
    {
      name: "B Phase PF",
      color: "#0000FF",
      data: b_phase_Power_Factor_of_current_month,
    },
  ];

  const lineChartOfFrequencyVariations: any = [
    {
      name: "Frequency",
      color: "#008000",
      data: frequency_of_current_month,
      postfix: " Hz",
    },
  ];
  //Frequency Data Ends

  const lineChartOfTHDVoltageVariations: any = [
    {
      name: "R Phase THD Voltage",
      color: "#008000",
      data: r_phase_THD_Voltage_of_current_month,
      postfix: " %",
    },
    {
      name: "Y Phase THD Voltage",
      color: "#FF0000",
      data: y_phase_THD_Voltage_of_current_month,
      postfix: " %",
    },
    {
      name: "B Phase THD Voltage",
      color: "#FFFF00",
      data: b_phase_THD_Voltage_of_current_month,
      postfix: " %",
    },
  ];

  const lineChartOfTHDCurrentVariations: any = [
    {
      name: "R Phase THD Current",
      color: "#008000",
      data: r_phase_THD_Current_of_current_month,
      postfix: " %",
    },
    {
      name: "Y Phase THD Current",
      color: "#FF0000",
      data: y_phase_THD_Current_of_current_month,
      postfix: " %",
    },
    {
      name: "B Phase THD Current",
      color: "#FFFF00",
      data: b_phase_THD_Current_of_current_month,
      postfix: " %",
    },
  ];
  //THD Data Ends

  const getPowerDataToExport = (valueInstPower: number) => {
    if (valueInstPower === 0) {
      return apiData.map(
        ({ timestamp, totalactpo, rphactpo, yphactpo, bphactpo }: any) => ({
          timestamp,
          "Total Active (kW)": totalactpo,
          "R Phase Active (kW)": rphactpo,
          "Y Phase Active (kW)": yphactpo,
          "B Phase Active (kW)": bphactpo,
        })
      );
    } else if (valueInstPower === 1) {
      return apiData.map(
        ({
          timestamp,
          totalreactpo,
          rphreactpo,
          yphreactpo,
          bphreactpo,
        }: any) => ({
          timestamp,
          "Total Reactive (kVAr)": totalreactpo,
          "R Phase Reactive (kVAr)": rphreactpo,
          "Y Phase Reactive (kVAr)": yphreactpo,
          "B Phase Reactive (kVAr)": bphreactpo,
        })
      );
    } else if (valueInstPower === 2) {
      return apiData.map(
        ({ timestamp, totalapppo, rphapppo, yphapppo, bphapppo }: any) => ({
          timestamp,
          "Total Apparent (kVa)": totalapppo,
          "R Phase Apparent (kVa)": rphapppo,
          "Y Phase Apparent (kVa)": yphapppo,
          "B Phase Apparent (kVa)": bphapppo,
        })
      );
    }
  };

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportDataWithGraph(
            chart,
            dataValue,
            devices?.deviceName,
            devices?.Customer?.name,
            "Energy Summary",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  let emptyBar: any[] | undefined;
  let consumtionData: any[] | undefined;
  if (valueAnalysis === 0) {
    const filterLast24hourDataOfEachHOur: any =
      filterDataFromEachHourEnergy(apiData);
    if (filterLast24hourDataOfEachHOur.length > 0) {
      const hourData = processHourlyDataEnergy(filterLast24hourDataOfEachHOur);
      consumtionData = processConsumptionDataEnergy(hourData);
      emptyBar = consumtionData ? fillMissingHours(consumtionData) : [];
    }
  } else if (valueAnalysis === 1) {
    let t: any = filterDataFromEachDayEnergy(apiData);
    if (t.length > 0) {
      const dailyData = processHourlyDataEnergyDaily(t);
      consumtionData = processMonthlyConsumptionEnergy(dailyData);

      emptyBar = consumtionData ? fillMissingDays(consumtionData) : [];
    }
  }

  //for max demand
  let maxDemand, maxDemadTime;

  let eachHalfHour = filterDataFromEachHalfHourEnergy(apiData);
  if (eachHalfHour.length > 0) {
    let t = processHalfHourlyDataEnergy(eachHalfHour);
    let valueArray = processConsumptionHalfHourDataEnergy(t);
    let maxDemandObject = valueArray
      .slice()
      .sort((a, b) => b["activeconsumption"] - a["activeconsumption"])[0];
    maxDemand = maxDemandObject.activeconsumption / 0.5;
    maxDemadTime = maxDemandObject.halfHour;
  }

  const bargraph: any = [
    {
      name: "Active Energy",
      color: "#101828",
      values: emptyBar
        ? emptyBar.map((item: any) => item.activeconsumption)
        : [],
      postfix: " kWh",
    },
    {
      name: "Reactive Energy",
      color: "#0777A7",
      values: emptyBar
        ? emptyBar.map((item: any) => item.reactiveconsumption)
        : [],
      postfix: " kVarh",
    },
    {
      name: "Apparent Energy",
      color: "#5EAFCF",
      values: emptyBar
        ? emptyBar.map((item: any) => item.apparentconsumption)
        : [],
      postfix: " kVAh",
    },
  ];
  const xAxisCategories = emptyBar
    ? emptyBar.map((item) =>
        valueAnalysis === 0 ? item.hour + ":00" : item.day
      )
    : [];
console.log('xAxisCategories1212',xAxisCategories)
  const handleTabClick = useCallback((index: number) => {
    setvalueAnalysis(+index);
    // fetchData(index);
  }, []);

  const handleTabClick1 = useCallback((index: number) => {
    setValue(+index);
    // fetchData(valueAnalysis);
    
  }, []);

  return (
    <div>
      <div className="p-2 mb-4">
        <section style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <MobileTowerIcon
                levels={dashboardSts?.rssi || 0}
                time={dashboardSts?.timestamp}
              />
              <p className="ml-2">
                {"Last Data Received at " +
                  (dashboardSts?.timestamp || "YYYY-MM-DD HH:MM:SS")}
              </p>
            </div>
          </div>

          <div className="w-[280px] ">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              <li className="w-full focus-within:z-10">
                <button
                 
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(0)}
                >
                  Analysis
                </button>
              </li>

              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(1)}
                >
                  Instantaneous
                </button>
              </li>
            </ul>
          </div>
        </section>
      </div>
      {value === 0 && (
        <div className="border border-gray-400 p-2 mb-4 mt-5">
          <div className="w-[350px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              {["Meter Reading", "Daily Consumption", ,].map((item, index) => (
                <li key={item} className="w-full focus-within:z-10">
                  <button
                    className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 ${
                      index === 0 ? "rounded-s-lg" : "rounded-e-lg"
                    } transition-colors duration-300`}
                    style={
                      valueAnalysis === index
                        ? { backgroundColor: "#dadada" }
                        : {}
                    }
                    onClick={() => handleTabClick(index)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {value === 0 && valueAnalysis === 0 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                {/* First Column */}
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  {/* Content for the first column */}
                  {/* You can add your bar graph component here */}
                  <div className="grid grid-cols-4 gap-5 my-cardTwo">
                   
                    <CardTwo
                      title="Max Demand"
                      humidity={`${formatNumericProperty(maxDemand)} kWh`}
                      icon={demandIcon}
                      background="#FEE4E2"
                      title2={`${maxDemadTime}`}
                    />
                    
                    <CardThree
                      title="Active Energy"
                      amount={
                        active_energy_last24hour.length === 0
                          ? "0 kWh"
                          : `${
                              active_energy_last24hour[
                                active_energy_last24hour.length - 1
                              ].value
                            } kWh`
                      }
                      background="#D1FADF"
                    />
                    <CardThree
                      title="Reactive Energy"
                      amount={
                        reactive_energy_last24hour.length === 0
                          ? "0 kVarh"
                          : `${
                              reactive_energy_last24hour[
                                reactive_energy_last24hour.length - 1
                              ].value
                            } kVarh`
                      }
                      background="#FEF0C7"
                    />
                    <CardThree
                      title="Apparent Energy"
                      amount={
                        apparent_energy_last24hour.length === 0
                          ? "0 kVah"
                          : `${
                              apparent_energy_last24hour[
                                apparent_energy_last24hour.length - 1
                              ].value
                            } kVah`
                      }
                      background="#E7E7FF"
                    />
                  </div>
                </div>

                {/* Space between columns */}
                <div className="h-4"></div>

                {/* Second Column */}
                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  {/* Content for the second column */}
                  {/* You can add your bar graph component here */}
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <p>
                          Energy meter reading data variation with time(between{" "}
                          {apiData.length > 0
                            ? apiData[0].timestamp +
                              " to " +
                              apiData[apiData.length - 1].timestamp
                            : " "}
                          )
                        </p>
                        <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                energy_data_pdfData,
                                chartRef
                              )
                            }
                            customstyle={{padding: '0px'}}
                            id={1}
                          />
                        </div>
                        <div>
                          <div
                            ref={chartRef || ""}
                            style={{ marginTop: "20px" }}
                          >
                            <LineChart
                              data={linechartEnergyData}
                              yAxisName="Energy Data"
                              xAxisName="Time"
                              stroke={true}
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-10">
                          <div className="relative mb-2 mt-1">
                            <p>
                              Hourly energy data variation with time(between{" "}
                              {emptyBar
                                ? emptyBar[0].hour +
                                  ":00 to " +
                                  emptyBar[emptyBar.length - 1].hour +
                                  ":00"
                                : " "}
                              )
                            </p>
                            <div className="absolute top-0 right-0">
                              <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(exportOption) =>
                                  handleExportBarChart(
                                    exportOption,
                                    consumtionData?.map(
                                      ({
                                        hour,
                                        activeconsumption,
                                        reactiveconsumption,
                                        apparentconsumption,
                                        firstTimeStamp,
                                        lastTimeStamp,
                                      }: any) => {
                                        return {
                                          Hour: hour + ": 00",
                                          "First Timestamp": firstTimeStamp,
                                          "Last Timestamp": lastTimeStamp,
                                          "Active Energy (kwh)":
                                            activeconsumption,
                                          "Reactive Energy (kvarh)":
                                            reactiveconsumption,
                                          "Apparent Energy (kvah)":
                                            apparentconsumption,
                                        };
                                      }
                                    ),
                                    barchartref1
                                  )
                                }
                                customstyle={{padding: '0px'}}
                                id={1}
                              />
                            </div>
                          </div>
                          <div ref={barchartref1 || ""}>
                            <BarChart
                              data={bargraph}
                              xAxisCategories={xAxisCategories}
                              yAxisName="Energy Consumption"
                              xAxisName="Time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {value === 0 && valueAnalysis === 1 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                {/* First Column */}
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  {/* Content for the first column */}
                  {/* You can add your bar graph component here */}
                  <div className="grid grid-cols-4 gap-5 my-cardTwo">
                    <CardTwo
                      title="Max Demand"
                      humidity={`${formatNumericProperty(maxDemand)} kWh`}
                      icon={demandIcon}
                      background="#FEE4E2"
                      title2={`${maxDemadTime}`}
                    />
                    <CardThree
                      title="Active Energy"
                      amount={`${
                        consumtionData
                          ? consumtionData[consumtionData.length - 1]
                              .activeconsumption
                          : 0
                      } kWh`}
                      background="#D1FADF"
                    />
                    <CardThree
                      title="Reactive Energy"
                      amount={`${
                        consumtionData
                          ? consumtionData[consumtionData.length - 1]
                              .reactiveconsumption
                          : 0
                      } kVarh`}
                      background="#FEF0C7"
                    />
                    <CardThree
                      title="Apparent Energy"
                      amount={`${
                        consumtionData
                          ? consumtionData[consumtionData.length - 1]
                              .apparentconsumption
                          : 0
                      } kVah`}
                      background="#E7E7FF"
                    />
                  </div>
                </div>

                {/* Space between columns */}
                <div className="h-4"></div>

                {/* Second Column */}
                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  {/* Content for the second column */}
                  {/* You can add your bar graph component here */}
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <p>
                          Energy meter reading data variation with time(between{" "}
                          {apiData.length > 0
                            ? apiData[0].timestamp +
                              " to " +
                              apiData[apiData.length - 1].timestamp
                            : " "}
                          )
                        </p>
                        <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                energy_data_pdfData,
                                chartRef2
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                        </div>
                        <div>
                          <div
                            ref={chartRef2 || ""}
                            style={{ paddingTop: "20px" }}
                          >
                            <LineChart
                              data={linechartEnergyData}
                              yAxisName="Energy Data"
                              xAxisName="Time"
                              stroke={true}
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-10">
                          <div className="relative mb-2 mt-1">
                            <p>
                              Daily energy data variation with time(between{" "}
                              {emptyBar
                                ? emptyBar[0].day +
                                  " to " +
                                  emptyBar[emptyBar.length - 1].day
                                : " "}
                              )
                            </p>
                            <div className="absolute top-0 right-0">
                              <LongMenu
                                options={["Download as CSV", "Download as PDF"]}
                                onOptionClick={(exportOption) =>
                                  handleExportBarChart(
                                    exportOption,
                                    consumtionData?.map(
                                      ({
                                        day,
                                        activeconsumption,
                                        reactiveconsumption,
                                        apparentconsumption,
                                        firstTimeStamp,
                                        lastTimeStamp,
                                      }: any) => {
                                        return {
                                          Day: day,
                                          "First Timestamp": firstTimeStamp,
                                          "Last Timestamp": lastTimeStamp,
                                          "Active Energy (kwh)":
                                            activeconsumption,
                                          "Reactive Energy (kvarh)":
                                            reactiveconsumption,
                                          "Apparent Energy(kvah)":
                                            apparentconsumption,
                                        };
                                      }
                                    ),
                                    barchartref2
                                  )
                                }
                                id={1}
                                customstyle={{padding: '0px'}}
                              />
                            </div>
                          </div>
                          <div ref={barchartref2 || ""}>
                            <BarChart
                              data={bargraph}
                              xAxisCategories={xAxisCategories}
                              yAxisName="Energy Consumption"
                              xAxisName="Time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {value === 1 && (
        <div>
          <div className="w-[600px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
              <li className="focus-within:z-10">
                <button
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={valueInst === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(0)}
                >
                  Voltage
                </button>
              </li>

              <li className="focus-within:z-10">
                <button
             
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                  style={valueInst === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(1)}
                >
                  Current
                </button>
              </li>
              <li className="focus-within:z-10">
                <button
                
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900   transition-colors duration-300`}
                  aria-current="page"
                  style={valueInst === 2 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(2)}
                >
                  Power
                </button>
              </li>
              <li className="focus-within:z-10">
                <button
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                  aria-current="page"
                  style={valueInst === 3 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(3)}
                >
                  Power Factor
                </button>
              </li>
              <li className="focus-within:z-10">
                <button
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                  aria-current="page"
                  style={valueInst === 4 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(4)}
                >
                  Frequency
                </button>
              </li>
              <li className="focus-within:z-10">
                <button
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  aria-current="page"
                  style={valueInst === 5 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setInst(5)}
                >
                  THD
                </button>
              </li>
            </ul>
          </div>
          <div
            className="my_temp_humidity"
            style={{ border: "2px solid #EAECF0", padding: "10px" }}
          >
            {value === 1 && valueInst === 0 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="Average Voltage"
                    amount={`${dashboardSts.avgvtg} V`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphvtg} V`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphvtg} V`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphvtg} V`}
                    background="#E7E7FF"
                  />
                  <div></div>
                  <CardThree
                    title="RY Phase"
                    amount={`${dashboardSts.ryphvtg} V`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="YB Phase"
                    amount={`${dashboardSts.ybpgvtg} V`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="RB Phase"
                    amount={`${dashboardSts.rbphvtg} V`}
                    background="#E7E7FF"
                  />
                </div>
                <div className="pb-[10px] border border-gray-400 rounded-md p-2">
                  <div className="relative  mt-2 mb-5">
                    <p>
                      Voltage data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>

                    <div className="w-[600px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                        <li className="focus-within:z-10">
                          <button
                            className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstVolt === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setValueInstVolt(0)}
                          >
                            Line wise
                          </button>
                        </li>

                        <li className="focus-within:z-10">
                          <button
                            className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstVolt === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setValueInstVolt(1)}
                          >
                            Line to line
                          </button>
                        </li>
                      </ul>
                    </div>

                    {valueInst === 0 && valueInstVolt === 0 && (
                      <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                intLWVoltPdfData,
                                chartRef1
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    )}
                    {valueInst === 0 && valueInstVolt === 1 && (
                      <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                intLLVoltPdfData,
                                chartRef1
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    )}
                  </div>
                  {valueInst === 0 && valueInstVolt === 0 && (
                    <div ref={chartRef1}>
                      <LineChart
                        data={lineChartOfLWVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Voltage (V)"
                      />
                    </div>
                  )}
                  {valueInst === 0 && valueInstVolt === 1 && (
                    <div ref={chartRef1}>
                      <LineChart
                        data={lineChartOfLLVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Voltage (V)"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {value === 1 && valueInst === 1 && (
              <div>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="Avarage Current"
                    amount={`${dashboardSts.avgcrt} A`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphcrt} A`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphcrt} A`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphcrt} A`}
                    background="#E7E7FF"
                  />
                </div>
                <div className="pb-[20px]">
                  <div className="relative mt-2 mb-5">
                    <p>
                      Current data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>
                    <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              intCurrPdfData,
                              chartRef4
                            )
                          }
                          id={1}
                          customstyle={{padding: '0px'}}
                        />
                    </div>
                    <div ref={chartRef4}>
                      <LineChart
                        data={lineChartOfCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Current (A)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {value === 1 && valueInst === 2 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="Total Active"
                    amount={`${dashboardSts.totalactpo} kW`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphactpo} kW`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphactpo} kW`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphactpo} kW`}
                    background="#E7E7FF"
                  />
                  <CardThree
                    title="Total Reactive"
                    amount={`${dashboardSts.totalreactpo} kVAr`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphreactpo} kVAr`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphreactpo} kVAr`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphreactpo} kVAr`}
                    background="#E7E7FF"
                  />
                  <CardThree
                    title="Total Apparent"
                    amount={`${dashboardSts.totalapppo} kVa`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphapppo} kVa`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphapppo} kVa`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphapppo} kVa`}
                    background="#E7E7FF"
                  />
                </div>
                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getPowerDataToExport(valueInstPower),
                                chartRef5
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    </div>
                    <div className="w-[270px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(0)}
                          >
                            Active
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                            style={
                              valueInstPower === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(1)}
                          >
                            Reactive
                          </button>
                        </li>
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 2
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(2)}
                          >
                            Apparent
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef5}>
                    {valueInst === 2 && valueInstPower === 0 && (
                      <LineChart
                        data={lineChartOfActivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Power (kW)"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 1 && (
                      <LineChart
                        data={lineChartOfReactivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Power (kVAr)"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 2 && (
                      <LineChart
                        data={lineChartOfApparentPowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Power (kVa)"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 3 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="Average PF"
                    amount={`${dashboardSts.powerfactor}`}
                    background="#D1FADF"
                  />
                  <CardThree
                    title="R Phase"
                    amount={`${dashboardSts.rphpf}`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase"
                    amount={`${dashboardSts.yphpf}`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase"
                    amount={`${dashboardSts.bphpf}`}
                    background="#E7E7FF"
                  />
                </div>
                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power Factor data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getPowerFactorData,
                                chartRef6
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef6}>
                    <LineChart
                      data={lineChartOfPowerFactorVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Power Factor"
                    />
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 4 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="Frequency"
                    amount={`${dashboardSts.frequency} Hz`}
                    background="#D1FADF"
                  />
                </div>
                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Frequency data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                       
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                frequency_for_pdf,
                                chartRef7
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef7}>
                    <LineChart
                      data={lineChartOfFrequencyVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Frequency (Hz)"
                    />
                  </div>
                </div>
              </>
            )}
            {value === 1 && valueInst === 5 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  <CardThree
                    title="R Phase Voltage"
                    amount={`${dashboardSts.rvtgphthd} %`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase Voltage"
                    amount={`${dashboardSts.yvtgphthd} %`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase Voltage"
                    amount={`${dashboardSts.bvtgphthd} %`}
                    background="#E7E7FF"
                  />
                  <div></div>
                  <CardThree
                    title="R Phase Current"
                    amount={`${dashboardSts.rcrtphthd} %`}
                    background="#FEE4E2"
                  />
                  <CardThree
                    title="Y Phase Current"
                    amount={`${dashboardSts.ycrtphthd} %`}
                    background="#FEF0C7"
                  />
                  <CardThree
                    title="B Phase Current"
                    amount={`${dashboardSts.bcrtphthd} %`}
                    background="#E7E7FF"
                  />
                  <div></div>
                </div>
                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Threshold data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                  
                          <LongMenu
                            options={["Download as CSV", "Download as PDF"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                getTDHDataToExport,
                                chartRef8
                              )
                            }
                            id={1}
                            customstyle={{padding: '0px'}}
                          />
                      </div>
                    </div>
                    <div className="w-[180px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstTDH === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(0)}
                          >
                            Voltage
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstTDH === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(1)}
                          >
                            Current
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef8}>
                    {valueInst === 5 && valueInstTDH === 0 && (
                      <LineChart
                        data={lineChartOfTHDVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Voltage (%)"
                      />
                    )}
                    {valueInst === 5 && valueInstTDH === 1 && (
                      <LineChart
                        data={lineChartOfTHDCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Current (%)"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardEnergy1;
