import { useEffect, useRef, useState } from "react";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import LineChart from "../../../components/apex-chart/LineChart";
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import {
  formatTimestamp,
  generateCSV,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";
import { http } from "../../../helper/http";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { Button, ButtonGroup } from "@material-ui/core";
import BarChart from "../../../components/apex-chart/CommonBarChart";
import Battery from "../../../components/apex-chart/Battery";
import {
  fillMissingHours,
  filterDataFromEachHourLast24HourAtlanta,
  processConsumptionDataAtlanta,
  processHourlyDataAtlanta,
} from "./common-function";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const baseUrl = process.env.REACT_APP_BASE_URL;
const column = [
  "TimeStamp",
  "Flow",
  "Flowrate",
  "Totalizer",
  "Forward Totalizer",
  "Reverse Totalizer",
  "Battery Level",
  "Unit",
];
const DashboardAtlanta = ({ devices, selectedDevice }: any) => {
  const [value, setValue] = useState(0);
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            flow: d["Inst_flow"],
            flowrate: d["Inst_flow_rate"],
            totalizer: d["Cumu_total"],
            "forward totalizer": d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
            "reverse totalizer": d["Inv_Cumu_Int"] + "." + d["Rev_Cumu_Dec"],
            "battery level": d["Batt_lvl"],
            unit: ["m³"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some((val: any) => {
            return typeof val === "string" && (val.toLowerCase() === "error" || val.toLowerCase() === "error.error");
          });
        });

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData
    .filter((entry) => {
      const entryDate = new Date(entry.timestamp);
      const timeDifference = currentDate.getTime() - entryDate.getTime();
      return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
    })
    .sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });

  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HourAtlanta(apiData);
    
  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyDataAtlanta(filterLast24hourDataOfEachHOur);
    
    consumtionData = processConsumptionDataAtlanta(hourData);
  }
  

  const flowlinechart =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, flow }: any) => ({
          timestamp,
          value: flow,
        }))
      : [];

  const flowratelinechart =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, flowrate }: any) => ({
          timestamp,
          value: flowrate,
        }))
      : [];

  const batterylevellinechart =
    last24HoursData.length > 0
      ? last24HoursData.map(
          ({ timestamp, "battery level": battery_level }: any) => ({
            timestamp,
            value: battery_level,
          })
        )
      : [];

  const flowlinegraph: any = [
    {
      name: "Flow",
      color: "#3cbd2b",
      data: flowlinechart,
      postfix: " m³/hr",
    },
  ];

  const flowratelinegraph: any = [
    {
      name: "Flowrate",
      color: "#3cbd2b",
      data: flowratelinechart,
      postfix: " m³/hr",
    },
  ];

  const batterylinegraph: any = [
    {
      name: "Battery Level",
      color: "#3cbd2b",
      data: batterylevellinechart,
      postfix: " %",
    },
  ];

  const emptyBar = consumtionData ? fillMissingHours(consumtionData) : [];
  const bargraph: any = [
    {
      name: "Totalizer Consumption",
      color: "#101828",
      values: emptyBar
        ? emptyBar.map((item: any) => item.totalizerconsumption)
        : [],
      postfix: " m³",
    },
    {
      name: "Forward Totalizer",
      color: "#0777A7",
      values: emptyBar
        ? emptyBar.map((item: any) => item.positivecumuconsumption)
        : [],
      postfix: " m³",
    },
    {
      name: "Reverse Totalizer",
      color: "#5EAFCF",
      values: emptyBar
        ? emptyBar.map((item: any) => item.negativecumuconsumption)
        : [],
      postfix: " m³",
    },
  ];

  const handleExportExcel = () => {
    generateCSV(column, last24HoursData);
  };

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData =
      value === 0
        ? flowlinechart?.map(({ timestamp, value }) => {
            return {
              TIMESTAMP: timestamp,
              FLOW: value,
              UNIT: "m³/hr",
            };
          })
        : value === 1
        ? flowratelinechart?.map(({ timestamp, value }) => {
            return {
              TIMESTAMP: timestamp,
              FLOWRATE: value,
              UNIT: "m³/hr",
            };
          })
        : value === 2
        ? batterylevellinechart?.map(({ timestamp, value }) => {
            return {
              TIMESTAMP: timestamp,
              BATTERY_LEVEL: value,
              UNIT: "%",
            };
          })
        : consumtionData && consumtionData.length > 0
        ? consumtionData.map(
            ({
              hour,
              firstTimeStamp,
              lastTimeStamp,
              totalizerconsumption,
              positivecumuconsumption,
              negativecumuconsumption,
            }: any) => {
              return {
                Hour: hour + ":00",
                "First Timestamp": firstTimeStamp,
                "Last Timestamp": lastTimeStamp,
                "Totalizer Consumtion": totalizerconsumption,
                "Forward Consumtion": positivecumuconsumption,
                "Reverse Consumtion": negativecumuconsumption,
              };
            }
          )
        : [];
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            value === 0 ? 'Flow Data Summary' : value === 1 ? 'Flowrate Data Summary' : value === 2 ? 'Battery Level Data Summary' : 'Totalizer Data Summary',
            last24HoursData?.length > 0
              ? emptyBar[0].hour+":00" +
                  " to " +
                  emptyBar[emptyBar?.length - 1].hour + ":00"
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };
  const xAxisCategories = emptyBar
    ? emptyBar.map((item) => item.hour + ":00")
    : [];

  return (
    <>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={dashboardSts?.rssi || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-[10px] mb-[5px] grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardTwo title="Flow" amount={`${dashboardSts?.flow || 0}  m³/hr`} />
        <CardTwo
          title="Flowrate"
          amount={`${dashboardSts?.flowrate || 0}  m³/hr`}
        />

        <CardTwo
          title="Totalizer"
          amount={`${dashboardSts?.totalizer || 0}  m³`}
        />

        <div className="rounded-lg border border-stroke p-3  bg-white">
          <div className="flex">
            <div className="w-3/4">
              <div className="mt-2 items-end justify-between">
                <div>
                  <h2 className="text-[16px]" style={{ whiteSpace: "nowrap" }}>
                    Battery Level
                  </h2>
                  <h4 className="text-title-md font-bold text-black text-[24px] py-1"></h4>
                </div>
              </div>
              <Battery level={dashboardSts["battery level"]} />
            </div>
          </div>
        </div>
        <CardTwo
          title="Forward Totalizer"
          amount={`${dashboardSts?.["forward totalizer"] || 0}  m³`}
        />
        <CardTwo
          title="Reverse Totalizer"
          amount={`${dashboardSts?.["reverse totalizer"] || 0}  m³`}
        />
      </div>

      <div className="border border-gray-400 p-2 rounded-md">
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as XLS",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <p>
          {value === 0
            ? "Flow "
            : value === 1
            ? "Flowrate "
            : value === 2
            ? "Battery Level "
            : "Totalizer "}{" "}
          {/* Data variation with time Last 24 Hours
           */}
          data variation with time (between{" "}
          {last24HoursData.length > 0
            ? last24HoursData[0].timestamp +
              " to " +
              last24HoursData[last24HoursData.length - 1].timestamp
            : " "}
          )
        </p>

        <div className="pb-[20px] pt-[10px]">
          {/* <ButtonGroup
            variant="outlined"
            color="default"
            aria-label="Basic button group"
          >
            <Button
              variant="outlined"
              style={value === 0 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(0)}
            >
              Flow
            </Button>
            <Button
              variant="outlined"
              style={value === 1 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(1)}
            >
              Flowrate
            </Button>
            <Button
              variant="outlined"
              style={value === 2 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(2)}
            >
              Battery Level
            </Button>
            <Button
              variant="outlined"
              style={value === 3 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(3)}
            >
              Totalizer
            </Button>
          </ButtonGroup> */}

          <div className="w-[410px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex ">
              <li className="focus-within:z-10">
                <a
                  href="#"
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={
                    value === 0 ? { backgroundColor: "#dadada" } : {}
                  }
                  onClick={() => setValue(0)}
                >
                  Flow
                </a>
              </li>

              <li className="focus-within:z-10">
                <a
                  href="#"
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900   transition-colors duration-300`}
                  style={
                    value === 1 ? { backgroundColor: "#dadada" } : {}
                  }
                  onClick={() => setValue(1)}
                >
                  Flowrate
                </a>
              </li>
              <li className="focus-within:z-10">
                <a
                  href="#"
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900   transition-colors duration-300`}
                  aria-current="page"
                  style={
                    value === 2 ? { backgroundColor: "#dadada" } : {}
                  }
                  onClick={() => setValue(2)}
                >
                  Battery Level
                </a>
              </li>
              <li className="focus-within:z-10">
                <a
                  href="#"
                  className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  rounded-e-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={
                    value === 3 ? { backgroundColor: "#dadada" } : {}
                  }
                  onClick={() => setValue(3)}
                >
                  Totalizer
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div ref={chartRef}>
          {value === 0 || value === 1 || value === 2 ? (
            <LineChart
              stroke={true}
              yAxisName={
                value === 0
                  ? "Flow (m³/hr) "
                  : value === 1
                  ? "Flowrate  (m³/hr)"
                  : "Battery Level (%)"
              }
              xAxisName="Time"
              data={
                value === 0
                  ? flowlinegraph
                  : value === 1
                  ? flowratelinegraph
                  : batterylinegraph
              }
            />
          ) : (
            <BarChart
              data={bargraph}
              xAxisCategories={xAxisCategories}
              yAxisName="Totalizer (m³)"
              xAxisName="Time"
            />
          )}

        </div>
      </div>
      <div className="h-2/6 overflow-auto shadow-xl mt-2 z-11 border border-gray-400 p-2 rounded-md ">
        <div className="flex items-center mb-4">
          <p className="font-semibold text-lg text-slate-600">
            Last 24 Hours Data Table
          </p>
          <div className="ml-auto">
            <IconButton>
              <DownloadIcon className="font-semibold text-lg text-slate-600" onClick={handleExportExcel} />
            </IconButton>
          </div>
        </div>
        <TabularComponents
          headers={column}
          rows={last24HoursData}
          pagination={true}
          sorting={true}
        />
      </div>
    </>
  );
};

export default DashboardAtlanta;
